import theme from '@chakra-ui/theme';

const primaryDisabledStyle = {
  opacity: 1,
  color: 'gray.400',
  bg: 'gray.200',
};

const secondaryDisabledStyle = {
  opacity: 1,
  color: 'gray.400',
  bg: 'transparent',
  borderWidth: '1px',
  borderColor: 'gray.300',
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    transparentBTN: {
      color: 'white',
      bg: 'whiteAlpha.300',
      px: '1rem',
      py: '0.5rem',
      fontSize: 'medium',
      fontWeight: 'medium',
      lineHeight: 'shorter',
      letterSpacing: 'wide',
      borderRadius: '100px',
    },
    darkBTN: {
      color: 'white',
      bg: 'black',
      px: '1.5rem',
      py: '0.5rem',
      fontSize: 'medium',
      fontWeight: 'medium',
      lineHeight: 'shorter',
      letterSpacing: 'wide',
      borderRadius: '100px',
    },
    fullWidthDarkBTN: {
      color: 'white',
      bg: 'black',
      px: '1.5rem',
      py: '1rem',
      w: '100%',
      fontSize: 'medium',
      fontWeight: 'medium',
      letterSpacing: 'wide',
      borderRadius: '100px',
    },
    fullWidthGreenBTN: {
      color: 'white',
      bg: 'brand.green',
      px: '1.5rem',
      py: '1rem',
      w: '100%',
      fontSize: 'medium',
      fontWeight: 'medium',
      lineHeight: 'shorter',
      letterSpacing: 'wide',
      borderRadius: '100px',
    },
    greenBTN: {
      color: 'white',
      bg: 'brand.green',
      px: '1.5rem',
      py: '1rem',
      fontSize: 'medium',
      fontWeight: 'medium',
      lineHeight: 'shorter',
      letterSpacing: 'wide',
      borderRadius: '100px',
    },
    borderdBTN: {
      color: 'black',
      px: '1.5rem',
      py: '0.5rem',
      fontSize: '.875rem',
      fontWeight: 'medium',
      letterSpacing: 'wide',
      borderRadius: '100px',
      border: '1px',
      borderColor: 'blackAlpha.900',
    },
    darkLabelBTN: {
      color: 'black',
      px: '1.5rem',
      py: '0.5rem',
      fontSize: '.875rem',
      fontWeight: 'medium',
      letterSpacing: 'wide',
    },
  },
  defaultProps: {},
};
