import { CognitoUser } from '@aws-amplify/auth';
import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { onSignInByEmail } from 'src/modules/auth/authService';
import { useAuthStore } from 'src/modules/auth/authStore';
import * as z from 'zod';

type Inputs = {
  email: string;
};

const schema = z.object({
  email: z.string().nonempty({ message: 'Email is required.' }).email({ message: 'Email is invalid!' }),
});

interface SignUpInFormsProps {
  onCognitoUser: (cognitoUser?: CognitoUser | null, email?: string) => void;
}

export const SignUpInForms: FC<SignUpInFormsProps> = (props) => {
  const { onCognitoUser } = props;
  const auth = useAuthStore((state) => state.me);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      email: auth?.email ?? '',
    },
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    if (auth?.email) {
      reset({ email: auth.email });
    } else {
      reset({ email: '' });
    }
  }, [auth]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { email } = data;

    await onSignInByEmail(email, (cognitoUser) => {
      onCognitoUser(cognitoUser, email);
    });

    // try {
    //   const { email } = data;
    //   if (email === auth?.email) {
    //     onClose();
    //     return;
    //   }
    //   await mutateAsync({ email });
    //   toast({
    //     position: 'top-right',
    //     title: 'Email updated successfully.',
    //     description: `Please check email ${email} and verify your new email address.`,
    //     status: 'success',
    //     duration: 15000,
    //     isClosable: true,
    //     containerStyle: { zIndex: 99999 },
    //   });
    //   if (auth) {
    //     setAuth({ ...auth, emailVerified: false, email: email });
    //   }
    //   onClose();
    // } catch (err: any) {
    //   /**
    //    * @TODO Add Logs here
    //    */
    //   toast({
    //     position: 'top-right',
    //     title: 'Error',
    //     description: err.response.data.message,
    //     status: 'error',
    //     duration: 5000,
    //     isClosable: true,
    //     containerStyle: { zIndex: 99999 },
    //   });
    // }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <FormControl isInvalid={errors.email && touchedFields.email}>
        <FormLabel htmlFor="email"><Text fontWeight="medium">Email address</Text></FormLabel>
        <Input id="email" type="text" {...register('email')} autoComplete="false" />
        {!errors.email ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>
      <Box textAlign="right">
        <Button variant="darkBTN" w="100%" m="1.25rem 0 2rem" isLoading={isSubmitting} type="submit" _hover={{ textDecoration: 'none', bgColor: 'black' }}>
          Let's go!
        </Button>
      </Box>
    </form>
  );
};
