import { Auth } from 'aws-amplify';
import Axios from 'axios';
import 'src/AmplifyConfig';
import { Me } from 'src/modules/auth/authStore';
import { CognitoUser } from '@aws-amplify/auth';
import Cookies from 'js-cookie';

export const onSignIn = async (setAuth: (auth: Me | null) => void, cb?: () => void) => {
  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length > 0) {
      const address = accounts[0];
      const cognitoUser = await handleAmplifySignIn(address);
      const messageToSign = cognitoUser.challengeParam.message;
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [address, messageToSign],
      });
      await Auth.sendCustomChallengeAnswer(cognitoUser, signature);
      await checkUser(setAuth);
    }
  } catch (err) {
    if (cb) {
      cb();
    }
  }
};

export const onSignInByEmail = async (email: string, cb?: (cognitoUser?: CognitoUser | null) => void) => {
  try {
    const cognitoUser = await handleAmplifySignIn(email);

    // console.log('cognitoUser.challengeParam: ', cognitoUser.challengeParam);

    // const signature = await window.ethereum.request({
    //   method: 'personal_sign',
    //   params: [address, messageToSign],
    // });
    // await Auth.sendCustomChallengeAnswer(cognitoUser, signature);
    // await checkUser(setAuth);
    if (cb) {
      cb(cognitoUser);
    }
  } catch (err) {
    if (cb) {
      cb();
    }
  }
};

export const handleAmplifySignIn = async (addressOrEmail: any): Promise<any> => {
  try {
    const cognitoUser = await Auth.signIn(addressOrEmail);
    return cognitoUser;
  } catch (err: any) {
    /*Cognito doesn't give us a lot of flexibility on error responses
      so we'll have to string match our 'User Not Found' error here
      and create a cognito user with the address as their username if they don't exist*/
    console.log('err.message: ', err.message);
    if (err.message === 'User does not exist.') {
      const params = {
        username: addressOrEmail,
        password: getRandomString(30),
      };
      await Auth.signUp(params);
      await Axios.post(`${process.env.NEXT_PUBLIC_API_GATEWAY_URL}/auth/create`, {
        email: addressOrEmail,
        username: addressOrEmail,
        __typename: 'USERS',
        status: 'ENABLED',
        role: 'User',
      });
      return handleAmplifySignIn(addressOrEmail);
    } else {
      throw err;
    }
  }
};

export const getRandomString = (bytes: any) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
};

export const intToHex = (nr: any) => {
  return nr.toString(16).padStart(2, '0');
};

export const onSignOut = async (setAuth: (auth: Me) => void, cb?: () => void) => {
  try {
    await Auth.signOut();
    await checkUser(setAuth);
    let hostName = window.location.hostname;

    Cookies.remove('userData', { path: '/', domain: hostName });
  } catch (err) {
    console.error('onSignOut error: ', err);
  }

  if (cb) {
    cb();
  }
};

export const checkUser = async (setAuth: (auth: Me) => void) => {
  try {
    await Auth.currentAuthenticatedUser();
    const meResponse = await Axios.get<Me>('/api/me');
    const user = meResponse.data;
    setAuth(user);
  } catch (err) {
    setAuth(null);
  }
};
