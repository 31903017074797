import '../styles/globals.css';
import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { QueryClientWrapper } from 'src/queryClient';
import theme from 'src/theme/theme';
import { useEffect } from 'react';
import 'src/AmplifyConfig';
import { useRouter } from 'next/router';
import * as gtag from '../lib/gtag';
import { EmailProvider } from 'src/modules/user/contexts/EmailProvider';
import MessengerChat from 'src/utils/MessengerChat';

const isProduction = process.env.VERCEL_ENV === 'production';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  let xenditTimeout: any = undefined;
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  // eslint-disable-next-line react/jsx-props-no-spreading

  useEffect(() => {
    if (xenditTimeout) {
      clearTimeout(xenditTimeout);
    }

    xenditTimeout = setTimeout(() => {
      handleXenditInitiation();
    }, 1000);
  }, []);

  const handleXenditInitiation = () => {
    if (window.Xendit) {
      window.Xendit.setPublishableKey(process.env.NEXT_PUBLIC_XENDIT_PUBLIC_API_KEY);
    }
  };

  return (
    <QueryClientWrapper>
      <ChakraProvider resetCSS theme={theme}>
        <EmailProvider>
          <Component {...pageProps} />
        </EmailProvider>
        <MessengerChat pageId="111118911519341" />
      </ChakraProvider>
    </QueryClientWrapper>
  );
}

export default MyApp;
