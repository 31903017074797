/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CampaignModel = {
  __typename: "CampaignModel",
  id: string,
  cid?: string | null,
  title?: string | null,
  description?: string | null,
  type?: CampaignType | null,
  celebrity?: Celebrity | null,
  userId?: string | null,
  phase?: Phase | null,
  typeAndPhase?: string | null,
  user?: UserModel | null,
  users?: ModelCampaignUserModelConnection | null,
  inactive?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export enum CampaignType {
  GIVE_AWAY = "GIVE_AWAY",
}


export enum Celebrity {
  ALDEN_RICHARDS = "ALDEN_RICHARDS",
  MARC_ABAYA = "MARC_ABAYA",
  MICHAEL_V = "MICHAEL_V",
  PEPE_SMITH = "PEPE_SMITH",
  TEAM_KRAMER = "TEAM_KRAMER",
  URBANDUB = "URBANDUB",
  RAZORBACK = "RAZORBACK",
  LEGIT_MISFITZ = "LEGIT_MISFITZ"
}


export enum Phase {
  PHASE_1 = "PHASE_1",
  PHASE_2 = "PHASE_2",
  PHASE_3 = "PHASE_3",
}


export type UserModel = {
  __typename: "UserModel",
  id: string,
  sub?: string | null,
  username?: string | null,
  walletAddress?: string | null,
  publicUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  cognitoStatus?: CognitoUserStatusType | null,
  status?: AccountStatus | null,
  role: ROLES,
  active?: boolean | null,
  campaigns?: ModelCampaignUserModelConnection | null,
  celebrity?: Celebrity | null,
  mnemonic?: string | null,
  privateKey?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  avatar?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum CognitoUserStatusType {
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  CONFIRMED = "CONFIRMED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  RESET_REQUIRED = "RESET_REQUIRED",
  UNCONFIRMED = "UNCONFIRMED",
  UNKNOWN = "UNKNOWN",
}


export enum AccountStatus {
  EMAIL_SENT = "EMAIL_SENT",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}


export enum ROLES {
  Admin = "Admin",
  Celebrity = "Celebrity",
  Artist = "Artist",
  User = "User",
}


export type ModelCampaignUserModelConnection = {
  __typename: "ModelCampaignUserModelConnection",
  items:  Array<CampaignUserModel | null >,
  nextToken?: string | null,
};

export type CampaignUserModel = {
  __typename: "CampaignUserModel",
  id: string,
  userId?: string | null,
  user?: UserModel | null,
  username?: string | null,
  campaignId?: string | null,
  campaign: CampaignModel,
  isNftSent?: boolean | null,
  collectionsSent?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserModelFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  username?: ModelStringInput | null,
  walletAddress?: ModelStringInput | null,
  publicUsername?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  emailVerified?: ModelBooleanInput | null,
  cognitoStatus?: ModelCognitoUserStatusTypeInput | null,
  status?: ModelAccountStatusInput | null,
  role?: ModelROLESInput | null,
  active?: ModelBooleanInput | null,
  celebrity?: ModelCelebrityInput | null,
  mnemonic?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  country?: ModelStringInput | null,
  streetLine1?: ModelStringInput | null,
  streetLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceState?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelUserModelFilterInput | null > | null,
  or?: Array< ModelUserModelFilterInput | null > | null,
  not?: ModelUserModelFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCognitoUserStatusTypeInput = {
  eq?: CognitoUserStatusType | null,
  ne?: CognitoUserStatusType | null,
};

export type ModelAccountStatusInput = {
  eq?: AccountStatus | null,
  ne?: AccountStatus | null,
};

export type ModelROLESInput = {
  eq?: ROLES | null,
  ne?: ROLES | null,
};

export type ModelCelebrityInput = {
  eq?: Celebrity | null,
  ne?: Celebrity | null,
};

export type ModelUserModelConnection = {
  __typename: "ModelUserModelConnection",
  items:  Array<UserModel | null >,
  nextToken?: string | null,
};

export type DeleteCampaignUserModelInput = {
  id: string,
};

export type ModelCampaignUserModelConditionInput = {
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  campaignId?: ModelIDInput | null,
  isNftSent?: ModelBooleanInput | null,
  collectionsSent?: ModelStringInput | null,
  and?: Array< ModelCampaignUserModelConditionInput | null > | null,
  or?: Array< ModelCampaignUserModelConditionInput | null > | null,
  not?: ModelCampaignUserModelConditionInput | null,
};

export type ModelTransactionModelFilterInput = {
  id?: ModelIDInput | null,
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  xenditOngoing?: ModelStringInput | null,
  xenditSuccess?: ModelStringInput | null,
  xenditError?: ModelStringInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  discount?: ModelFloatInput | null,
  celebrity_username?: ModelStringInput | null,
  entity?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  country?: ModelStringInput | null,
  streetLine1?: ModelStringInput | null,
  streetLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceState?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionModelFilterInput | null > | null,
  or?: Array< ModelTransactionModelFilterInput | null > | null,
  not?: ModelTransactionModelFilterInput | null,
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null,
  ne?: PaymentType | null,
};

export enum PaymentType {
  E_WALLET = "E_WALLET",
  CREDIT_CARD = "CREDIT_CARD",
  PAY_LATER = "PAY_LATER",
}


export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export enum PaymentStatus {
  ONGOING = "ONGOING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
}


export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTransactionModelConnection = {
  __typename: "ModelTransactionModelConnection",
  items:  Array<TransactionModel | null >,
  nextToken?: string | null,
};

export type TransactionModel = {
  __typename: "TransactionModel",
  id: string,
  xenditId?: string | null,
  user?: UserModel | null,
  userId?: string | null,
  product?: ProductModel | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  xenditOngoing?: string | null,
  xenditSuccess?: string | null,
  xenditError?: string | null,
  items?: ModelTransactionItemModelConnection | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  discount?: number | null,
  celebrity_username?: string | null,
  entity?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ProductModel = {
  __typename: "ProductModel",
  id: string,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  archived?: boolean | null,
  collections?: ModelCollectionModelConnection | null,
  tokenIds?: Array< number | null > | null,
  mainCollectionId?: string | null,
  discount?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCollectionModelConnection = {
  __typename: "ModelCollectionModelConnection",
  items:  Array<CollectionModel | null >,
  nextToken?: string | null,
};

export type CollectionModel = {
  __typename: "CollectionModel",
  id: string,
  address?: string | null,
  tag?: string | null,
  name?: string | null,
  description?: string | null,
  symbol?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  archived?: boolean | null,
  collectibleType?: CollectibleType | null,
  items?: ModelItemModelConnection | null,
  productId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum CollectibleType {
  BADGE = "BADGE",
  COMMON = "COMMON",
  RARE = "RARE",
  LEGENDARY = "LEGENDARY",
  COLLECTIBLE = "COLLECTIBLE",
}


export type ModelItemModelConnection = {
  __typename: "ModelItemModelConnection",
  items:  Array<ItemModel | null >,
  nextToken?: string | null,
};

export type ItemModel = {
  __typename: "ItemModel",
  id: string,
  name?: string | null,
  description?: string | null,
  smart_contract?: string | null,
  token_id?: number | null,
  collectibleType?: CollectibleType | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  isPresale?: boolean | null,
  archived?: boolean | null,
  collectionId?: string | null,
  isPurchased?: boolean | null,
  status?: ItemStatus | null,
  transactions?: ModelTransactionItemModelConnection | null,
  user?: UserModel | null,
  userId?: string | null,
  isSent?: boolean | null,
  smartContractTokenId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum ItemStatus {
  RESERVED = "RESERVED",
  SOLD = "SOLD",
  AVAILABLE = "AVAILABLE",
}


export type ModelTransactionItemModelConnection = {
  __typename: "ModelTransactionItemModelConnection",
  items:  Array<TransactionItemModel | null >,
  nextToken?: string | null,
};

export type TransactionItemModel = {
  __typename: "TransactionItemModel",
  id: string,
  itemId?: string | null,
  item?: ItemModel | null,
  transactionId?: string | null,
  transaction: TransactionModel,
  createdAt: string,
  updatedAt: string,
};

export type ModelProductModelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  tag?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  stock?: ModelIntInput | null,
  archived?: ModelBooleanInput | null,
  tokenIds?: ModelIntInput | null,
  mainCollectionId?: ModelIDInput | null,
  discount?: ModelFloatInput | null,
  and?: Array< ModelProductModelFilterInput | null > | null,
  or?: Array< ModelProductModelFilterInput | null > | null,
  not?: ModelProductModelFilterInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProductModelConnection = {
  __typename: "ModelProductModelConnection",
  items:  Array<ProductModel | null >,
  nextToken?: string | null,
};

export type UpdateUserModelInput = {
  id: string,
  sub?: string | null,
  username?: string | null,
  walletAddress?: string | null,
  publicUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  cognitoStatus?: CognitoUserStatusType | null,
  status?: AccountStatus | null,
  role?: ROLES | null,
  active?: boolean | null,
  celebrity?: Celebrity | null,
  mnemonic?: string | null,
  privateKey?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  avatar?: string | null,
};

export type ModelUserModelConditionInput = {
  sub?: ModelStringInput | null,
  username?: ModelStringInput | null,
  walletAddress?: ModelStringInput | null,
  publicUsername?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  emailVerified?: ModelBooleanInput | null,
  cognitoStatus?: ModelCognitoUserStatusTypeInput | null,
  status?: ModelAccountStatusInput | null,
  role?: ModelROLESInput | null,
  active?: ModelBooleanInput | null,
  celebrity?: ModelCelebrityInput | null,
  mnemonic?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  country?: ModelStringInput | null,
  streetLine1?: ModelStringInput | null,
  streetLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceState?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelUserModelConditionInput | null > | null,
  or?: Array< ModelUserModelConditionInput | null > | null,
  not?: ModelUserModelConditionInput | null,
};

export type ModelItemModelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  smart_contract?: ModelStringInput | null,
  token_id?: ModelIntInput | null,
  collectibleType?: ModelCollectibleTypeInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  background_color?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  tag?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  stock?: ModelIntInput | null,
  isPresale?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  collectionId?: ModelIDInput | null,
  isPurchased?: ModelBooleanInput | null,
  status?: ModelItemStatusInput | null,
  userId?: ModelIDInput | null,
  isSent?: ModelBooleanInput | null,
  smartContractTokenId?: ModelStringInput | null,
  and?: Array< ModelItemModelFilterInput | null > | null,
  or?: Array< ModelItemModelFilterInput | null > | null,
  not?: ModelItemModelFilterInput | null,
};

export type ModelCollectibleTypeInput = {
  eq?: CollectibleType | null,
  ne?: CollectibleType | null,
};

export type ModelItemStatusInput = {
  eq?: ItemStatus | null,
  ne?: ItemStatus | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelRecentTransactionReceiptModelFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentTransactionReceiptModelFilterInput | null > | null,
  or?: Array< ModelRecentTransactionReceiptModelFilterInput | null > | null,
  not?: ModelRecentTransactionReceiptModelFilterInput | null,
};

export type ModelRecentTransactionReceiptModelConnection = {
  __typename: "ModelRecentTransactionReceiptModelConnection",
  items:  Array<RecentTransactionReceiptModel | null >,
  nextToken?: string | null,
};

export type RecentTransactionReceiptModel = {
  __typename: "RecentTransactionReceiptModel",
  id: string,
  user?: UserModel | null,
  userId?: string | null,
  transactionId?: string | null,
  transaction?: TransactionModel | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type CreateUserModelInput = {
  id?: string | null,
  sub?: string | null,
  username?: string | null,
  walletAddress?: string | null,
  publicUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  cognitoStatus?: CognitoUserStatusType | null,
  status?: AccountStatus | null,
  role: ROLES,
  active?: boolean | null,
  celebrity?: Celebrity | null,
  mnemonic?: string | null,
  privateKey?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  avatar?: string | null,
};

export type DeleteUserModelInput = {
  id: string,
};

export type CreateVerificationTokenModelInput = {
  id?: string | null,
  token?: string | null,
  username?: string | null,
  type?: TokenType | null,
  createdAt?: string | null,
};

export enum TokenType {
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
}


export type ModelVerificationTokenModelConditionInput = {
  token?: ModelStringInput | null,
  username?: ModelStringInput | null,
  type?: ModelTokenTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVerificationTokenModelConditionInput | null > | null,
  or?: Array< ModelVerificationTokenModelConditionInput | null > | null,
  not?: ModelVerificationTokenModelConditionInput | null,
};

export type ModelTokenTypeInput = {
  eq?: TokenType | null,
  ne?: TokenType | null,
};

export type VerificationTokenModel = {
  __typename: "VerificationTokenModel",
  id: string,
  token?: string | null,
  username?: string | null,
  type?: TokenType | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVerificationTokenModelInput = {
  id: string,
  token?: string | null,
  username?: string | null,
  type?: TokenType | null,
  createdAt?: string | null,
};

export type DeleteVerificationTokenModelInput = {
  id: string,
};

export type CreateCampaignModelInput = {
  id?: string | null,
  cid?: string | null,
  title?: string | null,
  description?: string | null,
  type?: CampaignType | null,
  celebrity?: Celebrity | null,
  userId?: string | null,
  phase?: Phase | null,
  typeAndPhase?: string | null,
  inactive?: boolean | null,
  createdAt?: string | null,
};

export type ModelCampaignModelConditionInput = {
  cid?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelCampaignTypeInput | null,
  celebrity?: ModelCelebrityInput | null,
  userId?: ModelIDInput | null,
  phase?: ModelPhaseInput | null,
  typeAndPhase?: ModelStringInput | null,
  inactive?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCampaignModelConditionInput | null > | null,
  or?: Array< ModelCampaignModelConditionInput | null > | null,
  not?: ModelCampaignModelConditionInput | null,
};

export type ModelCampaignTypeInput = {
  eq?: CampaignType | null,
  ne?: CampaignType | null,
};

export type ModelPhaseInput = {
  eq?: Phase | null,
  ne?: Phase | null,
};

export type UpdateCampaignModelInput = {
  id: string,
  cid?: string | null,
  title?: string | null,
  description?: string | null,
  type?: CampaignType | null,
  celebrity?: Celebrity | null,
  userId?: string | null,
  phase?: Phase | null,
  typeAndPhase?: string | null,
  inactive?: boolean | null,
  createdAt?: string | null,
};

export type DeleteCampaignModelInput = {
  id: string,
};

export type CreateCampaignUserModelInput = {
  id?: string | null,
  userId?: string | null,
  username?: string | null,
  campaignId?: string | null,
  isNftSent?: boolean | null,
  collectionsSent?: Array< string | null > | null,
};

export type UpdateCampaignUserModelInput = {
  id: string,
  userId?: string | null,
  username?: string | null,
  campaignId?: string | null,
  isNftSent?: boolean | null,
  collectionsSent?: Array< string | null > | null,
};

export type CreateSecretsManagerInput = {
  id?: string | null,
  publicKey?: string | null,
  privateKey?: string | null,
  enabled?: boolean | null,
};

export type ModelSecretsManagerConditionInput = {
  publicKey?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelSecretsManagerConditionInput | null > | null,
  or?: Array< ModelSecretsManagerConditionInput | null > | null,
  not?: ModelSecretsManagerConditionInput | null,
};

export type SecretsManager = {
  __typename: "SecretsManager",
  id: string,
  publicKey?: string | null,
  privateKey?: string | null,
  enabled?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSecretsManagerInput = {
  id: string,
  publicKey?: string | null,
  privateKey?: string | null,
  enabled?: boolean | null,
};

export type DeleteSecretsManagerInput = {
  id: string,
};

export type CreatePageVisitModelInput = {
  pk: string,
  sk: string,
  celebrity?: Celebrity | null,
  pathName?: string | null,
  total?: number | null,
};

export type ModelPageVisitModelConditionInput = {
  celebrity?: ModelCelebrityInput | null,
  pathName?: ModelStringInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelPageVisitModelConditionInput | null > | null,
  or?: Array< ModelPageVisitModelConditionInput | null > | null,
  not?: ModelPageVisitModelConditionInput | null,
};

export type PageVisitModel = {
  __typename: "PageVisitModel",
  pk: string,
  sk: string,
  celebrity?: Celebrity | null,
  pathName?: string | null,
  total?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePageVisitModelInput = {
  pk: string,
  sk: string,
  celebrity?: Celebrity | null,
  pathName?: string | null,
  total?: number | null,
};

export type DeletePageVisitModelInput = {
  pk: string,
  sk: string,
};

export type CreateSmartContractModelInput = {
  id?: string | null,
  address?: string | null,
  celebrity?: Celebrity | null,
  collection?: string | null,
  name?: string | null,
  symbol?: string | null,
};

export type ModelSmartContractModelConditionInput = {
  address?: ModelStringInput | null,
  celebrity?: ModelCelebrityInput | null,
  collection?: ModelStringInput | null,
  name?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  and?: Array< ModelSmartContractModelConditionInput | null > | null,
  or?: Array< ModelSmartContractModelConditionInput | null > | null,
  not?: ModelSmartContractModelConditionInput | null,
};

export type SmartContractModel = {
  __typename: "SmartContractModel",
  id: string,
  address?: string | null,
  celebrity?: Celebrity | null,
  collection?: string | null,
  name?: string | null,
  symbol?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSmartContractModelInput = {
  id: string,
  address?: string | null,
  celebrity?: Celebrity | null,
  collection?: string | null,
  name?: string | null,
  symbol?: string | null,
};

export type DeleteSmartContractModelInput = {
  id: string,
};

export type CreateCollectionModelInput = {
  id?: string | null,
  address?: string | null,
  tag?: string | null,
  name?: string | null,
  description?: string | null,
  symbol?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  archived?: boolean | null,
  collectibleType?: CollectibleType | null,
  productId?: string | null,
};

export type ModelCollectionModelConditionInput = {
  address?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  background_color?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  collectibleType?: ModelCollectibleTypeInput | null,
  productId?: ModelIDInput | null,
  and?: Array< ModelCollectionModelConditionInput | null > | null,
  or?: Array< ModelCollectionModelConditionInput | null > | null,
  not?: ModelCollectionModelConditionInput | null,
};

export type UpdateCollectionModelInput = {
  id: string,
  address?: string | null,
  tag?: string | null,
  name?: string | null,
  description?: string | null,
  symbol?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  archived?: boolean | null,
  collectibleType?: CollectibleType | null,
  productId?: string | null,
};

export type DeleteCollectionModelInput = {
  id: string,
};

export type CreateItemModelInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  smart_contract?: string | null,
  token_id?: number | null,
  collectibleType?: CollectibleType | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  isPresale?: boolean | null,
  archived?: boolean | null,
  collectionId?: string | null,
  isPurchased?: boolean | null,
  status?: ItemStatus | null,
  userId?: string | null,
  isSent?: boolean | null,
  smartContractTokenId?: string | null,
};

export type ModelItemModelConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  smart_contract?: ModelStringInput | null,
  token_id?: ModelIntInput | null,
  collectibleType?: ModelCollectibleTypeInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  background_color?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  tag?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  stock?: ModelIntInput | null,
  isPresale?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  collectionId?: ModelIDInput | null,
  isPurchased?: ModelBooleanInput | null,
  status?: ModelItemStatusInput | null,
  userId?: ModelIDInput | null,
  isSent?: ModelBooleanInput | null,
  smartContractTokenId?: ModelStringInput | null,
  and?: Array< ModelItemModelConditionInput | null > | null,
  or?: Array< ModelItemModelConditionInput | null > | null,
  not?: ModelItemModelConditionInput | null,
};

export type UpdateItemModelInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  smart_contract?: string | null,
  token_id?: number | null,
  collectibleType?: CollectibleType | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  background_color?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  isPresale?: boolean | null,
  archived?: boolean | null,
  collectionId?: string | null,
  isPurchased?: boolean | null,
  status?: ItemStatus | null,
  userId?: string | null,
  isSent?: boolean | null,
  smartContractTokenId?: string | null,
};

export type DeleteItemModelInput = {
  id: string,
};

export type CreateTransactionModelInput = {
  id?: string | null,
  xenditId?: string | null,
  userId?: string | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  xenditOngoing?: string | null,
  xenditSuccess?: string | null,
  xenditError?: string | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  discount?: number | null,
  celebrity_username?: string | null,
  entity?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  createdAt?: string | null,
};

export type ModelTransactionModelConditionInput = {
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  xenditOngoing?: ModelStringInput | null,
  xenditSuccess?: ModelStringInput | null,
  xenditError?: ModelStringInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  discount?: ModelFloatInput | null,
  celebrity_username?: ModelStringInput | null,
  entity?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  country?: ModelStringInput | null,
  streetLine1?: ModelStringInput | null,
  streetLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceState?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionModelConditionInput | null > | null,
  or?: Array< ModelTransactionModelConditionInput | null > | null,
  not?: ModelTransactionModelConditionInput | null,
};

export type UpdateTransactionModelInput = {
  id: string,
  xenditId?: string | null,
  userId?: string | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  xenditOngoing?: string | null,
  xenditSuccess?: string | null,
  xenditError?: string | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  discount?: number | null,
  celebrity_username?: string | null,
  entity?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  mobileNumber?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  streetLine1?: string | null,
  streetLine2?: string | null,
  city?: string | null,
  provinceState?: string | null,
  postalCode?: string | null,
  createdAt?: string | null,
};

export type DeleteTransactionModelInput = {
  id: string,
};

export type CreateRecentTransactionModelInput = {
  id?: string | null,
  xenditId?: string | null,
  userId?: string | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
};

export type ModelRecentTransactionModelConditionInput = {
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentTransactionModelConditionInput | null > | null,
  or?: Array< ModelRecentTransactionModelConditionInput | null > | null,
  not?: ModelRecentTransactionModelConditionInput | null,
};

export type RecentTransactionModel = {
  __typename: "RecentTransactionModel",
  id: string,
  xenditId?: string | null,
  user?: UserModel | null,
  userId?: string | null,
  product?: ProductModel | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRecentTransactionModelInput = {
  id: string,
  xenditId?: string | null,
  userId?: string | null,
  productId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
};

export type DeleteRecentTransactionModelInput = {
  id: string,
};

export type CreateRecentFailedTransactionModelInput = {
  id?: string | null,
  xenditId?: string | null,
  userId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
};

export type ModelRecentFailedTransactionModelConditionInput = {
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentFailedTransactionModelConditionInput | null > | null,
  or?: Array< ModelRecentFailedTransactionModelConditionInput | null > | null,
  not?: ModelRecentFailedTransactionModelConditionInput | null,
};

export type RecentFailedTransactionModel = {
  __typename: "RecentFailedTransactionModel",
  id: string,
  xenditId?: string | null,
  user?: UserModel | null,
  userId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRecentFailedTransactionModelInput = {
  id: string,
  xenditId?: string | null,
  userId?: string | null,
  paymentType?: PaymentType | null,
  status?: PaymentStatus | null,
  price?: number | null,
  userIpAddress?: string | null,
  userCountry?: string | null,
  userRegion?: string | null,
  userCity?: string | null,
  userUserAgent?: string | null,
  ttl?: number | null,
};

export type DeleteRecentFailedTransactionModelInput = {
  id: string,
};

export type CreateTransactionItemModelInput = {
  id?: string | null,
  itemId?: string | null,
  transactionId?: string | null,
};

export type ModelTransactionItemModelConditionInput = {
  itemId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  and?: Array< ModelTransactionItemModelConditionInput | null > | null,
  or?: Array< ModelTransactionItemModelConditionInput | null > | null,
  not?: ModelTransactionItemModelConditionInput | null,
};

export type UpdateTransactionItemModelInput = {
  id: string,
  itemId?: string | null,
  transactionId?: string | null,
};

export type DeleteTransactionItemModelInput = {
  id: string,
};

export type CreateProductModelInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  archived?: boolean | null,
  tokenIds?: Array< number | null > | null,
  mainCollectionId?: string | null,
  discount?: number | null,
};

export type ModelProductModelConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  tag?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  stock?: ModelIntInput | null,
  archived?: ModelBooleanInput | null,
  tokenIds?: ModelIntInput | null,
  mainCollectionId?: ModelIDInput | null,
  discount?: ModelFloatInput | null,
  and?: Array< ModelProductModelConditionInput | null > | null,
  or?: Array< ModelProductModelConditionInput | null > | null,
  not?: ModelProductModelConditionInput | null,
};

export type UpdateProductModelInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  celebrity_username?: string | null,
  celebrity_country?: string | null,
  external_url?: string | null,
  youtube_url?: string | null,
  media_url?: string | null,
  properties?: string | null,
  price?: number | null,
  tag?: string | null,
  enabled?: boolean | null,
  stock?: number | null,
  archived?: boolean | null,
  tokenIds?: Array< number | null > | null,
  mainCollectionId?: string | null,
  discount?: number | null,
};

export type DeleteProductModelInput = {
  id: string,
};

export type CreateRecentTransactionReceiptModelInput = {
  id?: string | null,
  userId?: string | null,
  transactionId?: string | null,
  ttl?: number | null,
};

export type ModelRecentTransactionReceiptModelConditionInput = {
  userId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentTransactionReceiptModelConditionInput | null > | null,
  or?: Array< ModelRecentTransactionReceiptModelConditionInput | null > | null,
  not?: ModelRecentTransactionReceiptModelConditionInput | null,
};

export type UpdateRecentTransactionReceiptModelInput = {
  id: string,
  userId?: string | null,
  transactionId?: string | null,
  ttl?: number | null,
};

export type DeleteRecentTransactionReceiptModelInput = {
  id: string,
};

export type ModelVerificationTokenModelFilterInput = {
  id?: ModelIDInput | null,
  token?: ModelStringInput | null,
  username?: ModelStringInput | null,
  type?: ModelTokenTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVerificationTokenModelFilterInput | null > | null,
  or?: Array< ModelVerificationTokenModelFilterInput | null > | null,
  not?: ModelVerificationTokenModelFilterInput | null,
};

export type ModelVerificationTokenModelConnection = {
  __typename: "ModelVerificationTokenModelConnection",
  items:  Array<VerificationTokenModel | null >,
  nextToken?: string | null,
};

export type ModelCampaignModelFilterInput = {
  id?: ModelIDInput | null,
  cid?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelCampaignTypeInput | null,
  celebrity?: ModelCelebrityInput | null,
  userId?: ModelIDInput | null,
  phase?: ModelPhaseInput | null,
  typeAndPhase?: ModelStringInput | null,
  inactive?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCampaignModelFilterInput | null > | null,
  or?: Array< ModelCampaignModelFilterInput | null > | null,
  not?: ModelCampaignModelFilterInput | null,
};

export type ModelCampaignModelConnection = {
  __typename: "ModelCampaignModelConnection",
  items:  Array<CampaignModel | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCampaignUserModelFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  campaignId?: ModelIDInput | null,
  isNftSent?: ModelBooleanInput | null,
  collectionsSent?: ModelStringInput | null,
  and?: Array< ModelCampaignUserModelFilterInput | null > | null,
  or?: Array< ModelCampaignUserModelFilterInput | null > | null,
  not?: ModelCampaignUserModelFilterInput | null,
};

export type ModelSecretsManagerFilterInput = {
  id?: ModelIDInput | null,
  publicKey?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelSecretsManagerFilterInput | null > | null,
  or?: Array< ModelSecretsManagerFilterInput | null > | null,
  not?: ModelSecretsManagerFilterInput | null,
};

export type ModelSecretsManagerConnection = {
  __typename: "ModelSecretsManagerConnection",
  items:  Array<SecretsManager | null >,
  nextToken?: string | null,
};

export type ModelPageVisitModelFilterInput = {
  pk?: ModelStringInput | null,
  sk?: ModelStringInput | null,
  celebrity?: ModelCelebrityInput | null,
  pathName?: ModelStringInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelPageVisitModelFilterInput | null > | null,
  or?: Array< ModelPageVisitModelFilterInput | null > | null,
  not?: ModelPageVisitModelFilterInput | null,
};

export type ModelPageVisitModelConnection = {
  __typename: "ModelPageVisitModelConnection",
  items:  Array<PageVisitModel | null >,
  nextToken?: string | null,
};

export type ModelSmartContractModelFilterInput = {
  id?: ModelIDInput | null,
  address?: ModelStringInput | null,
  celebrity?: ModelCelebrityInput | null,
  collection?: ModelStringInput | null,
  name?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  and?: Array< ModelSmartContractModelFilterInput | null > | null,
  or?: Array< ModelSmartContractModelFilterInput | null > | null,
  not?: ModelSmartContractModelFilterInput | null,
};

export type ModelSmartContractModelConnection = {
  __typename: "ModelSmartContractModelConnection",
  items:  Array<SmartContractModel | null >,
  nextToken?: string | null,
};

export type ModelCollectionModelFilterInput = {
  id?: ModelIDInput | null,
  address?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  image?: ModelStringInput | null,
  celebrity_username?: ModelStringInput | null,
  celebrity_country?: ModelStringInput | null,
  external_url?: ModelStringInput | null,
  youtube_url?: ModelStringInput | null,
  media_url?: ModelStringInput | null,
  background_color?: ModelStringInput | null,
  properties?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  collectibleType?: ModelCollectibleTypeInput | null,
  productId?: ModelIDInput | null,
  and?: Array< ModelCollectionModelFilterInput | null > | null,
  or?: Array< ModelCollectionModelFilterInput | null > | null,
  not?: ModelCollectionModelFilterInput | null,
};

export type ModelRecentTransactionModelFilterInput = {
  id?: ModelIDInput | null,
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentTransactionModelFilterInput | null > | null,
  or?: Array< ModelRecentTransactionModelFilterInput | null > | null,
  not?: ModelRecentTransactionModelFilterInput | null,
};

export type ModelRecentTransactionModelConnection = {
  __typename: "ModelRecentTransactionModelConnection",
  items:  Array<RecentTransactionModel | null >,
  nextToken?: string | null,
};

export type ModelRecentFailedTransactionModelFilterInput = {
  id?: ModelIDInput | null,
  xenditId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  paymentType?: ModelPaymentTypeInput | null,
  status?: ModelPaymentStatusInput | null,
  price?: ModelFloatInput | null,
  userIpAddress?: ModelStringInput | null,
  userCountry?: ModelStringInput | null,
  userRegion?: ModelStringInput | null,
  userCity?: ModelStringInput | null,
  userUserAgent?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecentFailedTransactionModelFilterInput | null > | null,
  or?: Array< ModelRecentFailedTransactionModelFilterInput | null > | null,
  not?: ModelRecentFailedTransactionModelFilterInput | null,
};

export type ModelRecentFailedTransactionModelConnection = {
  __typename: "ModelRecentFailedTransactionModelConnection",
  items:  Array<RecentFailedTransactionModel | null >,
  nextToken?: string | null,
};

export type ModelTransactionItemModelFilterInput = {
  id?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  and?: Array< ModelTransactionItemModelFilterInput | null > | null,
  or?: Array< ModelTransactionItemModelFilterInput | null > | null,
  not?: ModelTransactionItemModelFilterInput | null,
};

export type ModelSubscriptionUserModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sub?: ModelSubscriptionStringInput | null,
  walletAddress?: ModelSubscriptionStringInput | null,
  publicUsername?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  emailVerified?: ModelSubscriptionBooleanInput | null,
  cognitoStatus?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  celebrity?: ModelSubscriptionStringInput | null,
  mnemonic?: ModelSubscriptionStringInput | null,
  privateKey?: ModelSubscriptionStringInput | null,
  mobileNumber?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  streetLine1?: ModelSubscriptionStringInput | null,
  streetLine2?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  provinceState?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserModelFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionVerificationTokenModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  token?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVerificationTokenModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionVerificationTokenModelFilterInput | null > | null,
};

export type ModelSubscriptionCampaignModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cid?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  celebrity?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  phase?: ModelSubscriptionStringInput | null,
  typeAndPhase?: ModelSubscriptionStringInput | null,
  inactive?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCampaignModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionCampaignModelFilterInput | null > | null,
};

export type ModelSubscriptionCampaignUserModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  campaignId?: ModelSubscriptionIDInput | null,
  isNftSent?: ModelSubscriptionBooleanInput | null,
  collectionsSent?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCampaignUserModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionCampaignUserModelFilterInput | null > | null,
};

export type ModelSubscriptionSecretsManagerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  publicKey?: ModelSubscriptionStringInput | null,
  privateKey?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionSecretsManagerFilterInput | null > | null,
  or?: Array< ModelSubscriptionSecretsManagerFilterInput | null > | null,
};

export type ModelSubscriptionPageVisitModelFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  sk?: ModelSubscriptionStringInput | null,
  celebrity?: ModelSubscriptionStringInput | null,
  pathName?: ModelSubscriptionStringInput | null,
  total?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPageVisitModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionPageVisitModelFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionSmartContractModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  address?: ModelSubscriptionStringInput | null,
  celebrity?: ModelSubscriptionStringInput | null,
  collection?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  symbol?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSmartContractModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionSmartContractModelFilterInput | null > | null,
};

export type ModelSubscriptionCollectionModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  address?: ModelSubscriptionStringInput | null,
  tag?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  symbol?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  celebrity_username?: ModelSubscriptionStringInput | null,
  celebrity_country?: ModelSubscriptionStringInput | null,
  external_url?: ModelSubscriptionStringInput | null,
  youtube_url?: ModelSubscriptionStringInput | null,
  media_url?: ModelSubscriptionStringInput | null,
  background_color?: ModelSubscriptionStringInput | null,
  properties?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  collectibleType?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCollectionModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionCollectionModelFilterInput | null > | null,
};

export type ModelSubscriptionItemModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  smart_contract?: ModelSubscriptionStringInput | null,
  token_id?: ModelSubscriptionIntInput | null,
  collectibleType?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  celebrity_username?: ModelSubscriptionStringInput | null,
  celebrity_country?: ModelSubscriptionStringInput | null,
  external_url?: ModelSubscriptionStringInput | null,
  youtube_url?: ModelSubscriptionStringInput | null,
  media_url?: ModelSubscriptionStringInput | null,
  background_color?: ModelSubscriptionStringInput | null,
  properties?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  tag?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  stock?: ModelSubscriptionIntInput | null,
  isPresale?: ModelSubscriptionBooleanInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  collectionId?: ModelSubscriptionIDInput | null,
  isPurchased?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  isSent?: ModelSubscriptionBooleanInput | null,
  smartContractTokenId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemModelFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTransactionModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  xenditId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  paymentType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  xenditOngoing?: ModelSubscriptionStringInput | null,
  xenditSuccess?: ModelSubscriptionStringInput | null,
  xenditError?: ModelSubscriptionStringInput | null,
  userIpAddress?: ModelSubscriptionStringInput | null,
  userCountry?: ModelSubscriptionStringInput | null,
  userRegion?: ModelSubscriptionStringInput | null,
  userCity?: ModelSubscriptionStringInput | null,
  userUserAgent?: ModelSubscriptionStringInput | null,
  discount?: ModelSubscriptionFloatInput | null,
  celebrity_username?: ModelSubscriptionStringInput | null,
  entity?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  mobileNumber?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  streetLine1?: ModelSubscriptionStringInput | null,
  streetLine2?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  provinceState?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionModelFilterInput | null > | null,
};

export type ModelSubscriptionRecentTransactionModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  xenditId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  paymentType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  userIpAddress?: ModelSubscriptionStringInput | null,
  userCountry?: ModelSubscriptionStringInput | null,
  userRegion?: ModelSubscriptionStringInput | null,
  userCity?: ModelSubscriptionStringInput | null,
  userUserAgent?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionRecentTransactionModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecentTransactionModelFilterInput | null > | null,
};

export type ModelSubscriptionRecentFailedTransactionModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  xenditId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  paymentType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  userIpAddress?: ModelSubscriptionStringInput | null,
  userCountry?: ModelSubscriptionStringInput | null,
  userRegion?: ModelSubscriptionStringInput | null,
  userCity?: ModelSubscriptionStringInput | null,
  userUserAgent?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionRecentFailedTransactionModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecentFailedTransactionModelFilterInput | null > | null,
};

export type ModelSubscriptionTransactionItemModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTransactionItemModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionItemModelFilterInput | null > | null,
};

export type ModelSubscriptionProductModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  celebrity_username?: ModelSubscriptionStringInput | null,
  celebrity_country?: ModelSubscriptionStringInput | null,
  external_url?: ModelSubscriptionStringInput | null,
  youtube_url?: ModelSubscriptionStringInput | null,
  media_url?: ModelSubscriptionStringInput | null,
  properties?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  tag?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  stock?: ModelSubscriptionIntInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  tokenIds?: ModelSubscriptionIntInput | null,
  mainCollectionId?: ModelSubscriptionIDInput | null,
  discount?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionProductModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductModelFilterInput | null > | null,
};

export type ModelSubscriptionRecentTransactionReceiptModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionRecentTransactionReceiptModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecentTransactionReceiptModelFilterInput | null > | null,
};

export type GetCampaignModelWithUsersQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignModelWithUsersQuery = {
  getCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      items:  Array< {
        __typename: "CampaignUserModel",
        campaignId?: string | null,
        createdAt: string,
        id: string,
        userId?: string | null,
        username?: string | null,
        collectionsSent?: Array< string | null > | null,
        updatedAt: string,
        user?:  {
          __typename: "UserModel",
          email?: string | null,
          walletAddress?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type getUserByUsernameWithoutPrivateKeyQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type getUserByUsernameWithoutPrivateKeyQuery = {
  getUserByUsername?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByEmailWithoutPrivateKeyQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByEmailWithoutPrivateKeyQuery = {
  getUserByEmail?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBySubWithoutPrivateKeyQueryVariables = {
  sub: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserBySubWithoutPrivateKeyQuery = {
  getUserBySub?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listUserModelsWithoutPrivateKeyQueryVariables = {
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listUserModelsWithoutPrivateKeyQuery = {
  listUserModels?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listUserModelsForTotalQueryVariables = {
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listUserModelsForTotalQuery = {
  listUserModels?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      emailVerified?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeleteCampaignUserModelWithoutPrivateKeyMutationVariables = {
  input: DeleteCampaignUserModelInput,
  condition?: ModelCampaignUserModelConditionInput | null,
};

export type DeleteCampaignUserModelWithoutPrivateKeyMutation = {
  deleteCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type listTransactionModelsFullQueryVariables = {
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listTransactionModelsFullQuery = {
  listTransactionModels?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "UserModel",
        id: string,
        email?: string | null,
        walletAddress?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listProductModelsWithCollectionsQueryVariables = {
  filter?: ModelProductModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listProductModelsWithCollectionsQuery = {
  listProductModels?:  {
    __typename: "ModelProductModelConnection",
    items:  Array< {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      discount?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      mainCollectionId?: string | null,
      collections?:  {
        __typename: "ModelCollectionModelConnection",
        items:  Array< {
          __typename: "CollectionModel",
          id: string,
          name?: string | null,
          description?: string | null,
          celebrity_country?: string | null,
          celebrity_username?: string | null,
          external_url?: string | null,
          image?: string | null,
          collectibleType?: CollectibleType | null,
          media_url?: string | null,
          tag?: string | null,
          youtube_url?: string | null,
          archived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductByCelebrityUsernameWithCollectionsQueryVariables = {
  celebrity_username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProductByCelebrityUsernameWithCollectionsQuery = {
  getProductByCelebrityUsername?:  {
    __typename: "ModelProductModelConnection",
    items:  Array< {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      collections?:  {
        __typename: "ModelCollectionModelConnection",
        items:  Array< {
          __typename: "CollectionModel",
          id: string,
          name?: string | null,
          description?: string | null,
          celebrity_country?: string | null,
          celebrity_username?: string | null,
          external_url?: string | null,
          image?: string | null,
          collectibleType?: CollectibleType | null,
          media_url?: string | null,
          tag?: string | null,
          youtube_url?: string | null,
          archived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getProductModelWithCollectionsQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type getProductModelWithCollectionsQuery = {
  getProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      items:  Array< {
        __typename: "CollectionModel",
        id: string,
        name?: string | null,
        description?: string | null,
        celebrity_country?: string | null,
        celebrity_username?: string | null,
        external_url?: string | null,
        image?: string | null,
        collectibleType?: CollectibleType | null,
        media_url?: string | null,
        tag?: string | null,
        youtube_url?: string | null,
        archived?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type getProductModelWithCollectionsAndItemsQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type getProductModelWithCollectionsAndItemsQuery = {
  getProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    mainCollectionId?: string | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      items:  Array< {
        __typename: "CollectionModel",
        id: string,
        address?: string | null,
        name?: string | null,
        description?: string | null,
        celebrity_country?: string | null,
        celebrity_username?: string | null,
        external_url?: string | null,
        image?: string | null,
        collectibleType?: CollectibleType | null,
        media_url?: string | null,
        tag?: string | null,
        youtube_url?: string | null,
        archived?: boolean | null,
        items?:  {
          __typename: "ModelItemModelConnection",
          items:  Array< {
            __typename: "ItemModel",
            id: string,
            name?: string | null,
            price?: number | null,
            token_id?: number | null,
            isPurchased?: boolean | null,
            status?: ItemStatus | null,
            userId?: string | null,
            user?:  {
              __typename: "UserModel",
              id: string,
              publicUsername?: string | null,
              avatar?: string | null,
              role: ROLES,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type getItemModelWithTransactionsQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type getItemModelWithTransactionsQuery = {
  getItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      items:  Array< {
        __typename: "TransactionItemModel",
        id: string,
        transactionId?: string | null,
        transaction:  {
          __typename: "TransactionModel",
          id: string,
          xenditId?: string | null,
          userId?: string | null,
          productId?: string | null,
          paymentType?: PaymentType | null,
          status?: PaymentStatus | null,
          price?: number | null,
          xenditOngoing?: string | null,
          xenditSuccess?: string | null,
          xenditError?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type getTransactionModelWithItemsQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type getTransactionModelWithItemsQuery = {
  getTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      items:  Array< {
        __typename: "TransactionItemModel",
        id: string,
        itemId?: string | null,
        item?:  {
          __typename: "ItemModel",
          id: string,
          isPurchased?: boolean | null,
          status?: ItemStatus | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTransactionModelWithItemsAndUserQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionModelWithItemsAndUserQuery = {
  getTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
    } | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    discount?: number | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      items:  Array< {
        __typename: "TransactionItemModel",
        id: string,
        itemId?: string | null,
        item?:  {
          __typename: "ItemModel",
          id: string,
          price?: number | null,
          isPurchased?: boolean | null,
          status?: ItemStatus | null,
          createdAt: string,
          updatedAt: string,
          name?: string | null,
          token_id?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserModelProfileMutationVariables = {
  input: UpdateUserModelInput,
  condition?: ModelUserModelConditionInput | null,
};

export type UpdateUserModelProfileMutation = {
  updateUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListItemModelsWithUserQueryVariables = {
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemModelsWithUserQuery = {
  listItemModels?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        email?: string | null,
        avatar?: string | null,
        publicUsername?: string | null,
        role: ROLES,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractWithUserQueryVariables = {
  smart_contract: string,
  token_id?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractWithUserQuery = {
  getItemBySmartContract?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        email?: string | null,
        walletAddress?: string | null,
      } | null,
      isSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractWithUserWithoutEmailQueryVariables = {
  smart_contract: string,
  token_id?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractWithUserWithoutEmailQuery = {
  getItemBySmartContract?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        publicUsername?: string | null,
        avatar?: string | null,
        walletAddress?: string | null,
        role: ROLES,
        createdAt: string,
        updatedAt: string,
      } | null,
      isSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReceiptByTransactionIdWithItemsQueryVariables = {
  transactionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecentTransactionReceiptModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetReceiptByTransactionIdWithItemsQuery = {
  getReceiptByTransactionId?:  {
    __typename: "ModelRecentTransactionReceiptModelConnection",
    items:  Array< {
      __typename: "RecentTransactionReceiptModel",
      id: string,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        walletAddress?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
      } | null,
      transactionId?: string | null,
      transaction?:  {
        __typename: "TransactionModel",
        id: string,
        xenditId?: string | null,
        productId?: string | null,
        paymentType?: PaymentType | null,
        status?: PaymentStatus | null,
        price?: number | null,
        discount?: number | null,
        items?:  {
          __typename: "ModelTransactionItemModelConnection",
          items:  Array< {
            __typename: "TransactionItemModel",
            id: string,
            itemId?: string | null,
            item?:  {
              __typename: "ItemModel",
              id: string,
              price?: number | null,
              name?: string | null,
              token_id?: number | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractWithUserWalletQueryVariables = {
  smart_contract: string,
  token_id?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractWithUserWalletQuery = {
  getItemBySmartContract?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        avatar?: string | null,
        walletAddress?: string | null,
      } | null,
      isSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemModelWithoutPrivateKeyQueryVariables = {
  id: string,
};

export type GetItemModelWithoutPrivateKeyQuery = {
  getItemModel?:  {
    __typename: "ItemModel",
    id: string,
    token_id?: number | null,
    collectionId?: string | null,
    status?: ItemStatus | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      username?: string | null,
      walletAddress?: string | null,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTransactionsByCelebrityUsernameWithProductQueryVariables = {
  celebrity_username: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionsByCelebrityUsernameWithProductQuery = {
  getTransactionsByCelebrityUsername?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
      } | null,
      productId?: string | null,
      product?:  {
        __typename: "ProductModel",
        id: string,
        name?: string | null,
        description?: string | null,
        image?: string | null,
        celebrity_username?: string | null,
        celebrity_country?: string | null,
        external_url?: string | null,
        youtube_url?: string | null,
        media_url?: string | null,
        properties?: string | null,
        price?: number | null,
        tag?: string | null,
        enabled?: boolean | null,
        stock?: number | null,
        archived?: boolean | null,
        discount?: number | null,
      } | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractTokenIdWithUserQueryVariables = {
  smartContractTokenId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractTokenIdWithUserQuery = {
  getItemBySmartContractTokenId?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      user?:  {
        __typename: "UserModel",
        id: string,
        walletAddress?: string | null,
      } | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateUserModelMutationVariables = {
  input: CreateUserModelInput,
  condition?: ModelUserModelConditionInput | null,
};

export type CreateUserModelMutation = {
  createUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserModelMutationVariables = {
  input: UpdateUserModelInput,
  condition?: ModelUserModelConditionInput | null,
};

export type UpdateUserModelMutation = {
  updateUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserModelMutationVariables = {
  input: DeleteUserModelInput,
  condition?: ModelUserModelConditionInput | null,
};

export type DeleteUserModelMutation = {
  deleteUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVerificationTokenModelMutationVariables = {
  input: CreateVerificationTokenModelInput,
  condition?: ModelVerificationTokenModelConditionInput | null,
};

export type CreateVerificationTokenModelMutation = {
  createVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVerificationTokenModelMutationVariables = {
  input: UpdateVerificationTokenModelInput,
  condition?: ModelVerificationTokenModelConditionInput | null,
};

export type UpdateVerificationTokenModelMutation = {
  updateVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVerificationTokenModelMutationVariables = {
  input: DeleteVerificationTokenModelInput,
  condition?: ModelVerificationTokenModelConditionInput | null,
};

export type DeleteVerificationTokenModelMutation = {
  deleteVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCampaignModelMutationVariables = {
  input: CreateCampaignModelInput,
  condition?: ModelCampaignModelConditionInput | null,
};

export type CreateCampaignModelMutation = {
  createCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCampaignModelMutationVariables = {
  input: UpdateCampaignModelInput,
  condition?: ModelCampaignModelConditionInput | null,
};

export type UpdateCampaignModelMutation = {
  updateCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCampaignModelMutationVariables = {
  input: DeleteCampaignModelInput,
  condition?: ModelCampaignModelConditionInput | null,
};

export type DeleteCampaignModelMutation = {
  deleteCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCampaignUserModelMutationVariables = {
  input: CreateCampaignUserModelInput,
  condition?: ModelCampaignUserModelConditionInput | null,
};

export type CreateCampaignUserModelMutation = {
  createCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCampaignUserModelMutationVariables = {
  input: UpdateCampaignUserModelInput,
  condition?: ModelCampaignUserModelConditionInput | null,
};

export type UpdateCampaignUserModelMutation = {
  updateCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCampaignUserModelMutationVariables = {
  input: DeleteCampaignUserModelInput,
  condition?: ModelCampaignUserModelConditionInput | null,
};

export type DeleteCampaignUserModelMutation = {
  deleteCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSecretsManagerMutationVariables = {
  input: CreateSecretsManagerInput,
  condition?: ModelSecretsManagerConditionInput | null,
};

export type CreateSecretsManagerMutation = {
  createSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecretsManagerMutationVariables = {
  input: UpdateSecretsManagerInput,
  condition?: ModelSecretsManagerConditionInput | null,
};

export type UpdateSecretsManagerMutation = {
  updateSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecretsManagerMutationVariables = {
  input: DeleteSecretsManagerInput,
  condition?: ModelSecretsManagerConditionInput | null,
};

export type DeleteSecretsManagerMutation = {
  deleteSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePageVisitModelMutationVariables = {
  input: CreatePageVisitModelInput,
  condition?: ModelPageVisitModelConditionInput | null,
};

export type CreatePageVisitModelMutation = {
  createPageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePageVisitModelMutationVariables = {
  input: UpdatePageVisitModelInput,
  condition?: ModelPageVisitModelConditionInput | null,
};

export type UpdatePageVisitModelMutation = {
  updatePageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePageVisitModelMutationVariables = {
  input: DeletePageVisitModelInput,
  condition?: ModelPageVisitModelConditionInput | null,
};

export type DeletePageVisitModelMutation = {
  deletePageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSmartContractModelMutationVariables = {
  input: CreateSmartContractModelInput,
  condition?: ModelSmartContractModelConditionInput | null,
};

export type CreateSmartContractModelMutation = {
  createSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSmartContractModelMutationVariables = {
  input: UpdateSmartContractModelInput,
  condition?: ModelSmartContractModelConditionInput | null,
};

export type UpdateSmartContractModelMutation = {
  updateSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSmartContractModelMutationVariables = {
  input: DeleteSmartContractModelInput,
  condition?: ModelSmartContractModelConditionInput | null,
};

export type DeleteSmartContractModelMutation = {
  deleteSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollectionModelMutationVariables = {
  input: CreateCollectionModelInput,
  condition?: ModelCollectionModelConditionInput | null,
};

export type CreateCollectionModelMutation = {
  createCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCollectionModelMutationVariables = {
  input: UpdateCollectionModelInput,
  condition?: ModelCollectionModelConditionInput | null,
};

export type UpdateCollectionModelMutation = {
  updateCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCollectionModelMutationVariables = {
  input: DeleteCollectionModelInput,
  condition?: ModelCollectionModelConditionInput | null,
};

export type DeleteCollectionModelMutation = {
  deleteCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateItemModelMutationVariables = {
  input: CreateItemModelInput,
  condition?: ModelItemModelConditionInput | null,
};

export type CreateItemModelMutation = {
  createItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateItemModelMutationVariables = {
  input: UpdateItemModelInput,
  condition?: ModelItemModelConditionInput | null,
};

export type UpdateItemModelMutation = {
  updateItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteItemModelMutationVariables = {
  input: DeleteItemModelInput,
  condition?: ModelItemModelConditionInput | null,
};

export type DeleteItemModelMutation = {
  deleteItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTransactionModelMutationVariables = {
  input: CreateTransactionModelInput,
  condition?: ModelTransactionModelConditionInput | null,
};

export type CreateTransactionModelMutation = {
  createTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTransactionModelMutationVariables = {
  input: UpdateTransactionModelInput,
  condition?: ModelTransactionModelConditionInput | null,
};

export type UpdateTransactionModelMutation = {
  updateTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTransactionModelMutationVariables = {
  input: DeleteTransactionModelInput,
  condition?: ModelTransactionModelConditionInput | null,
};

export type DeleteTransactionModelMutation = {
  deleteTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRecentTransactionModelMutationVariables = {
  input: CreateRecentTransactionModelInput,
  condition?: ModelRecentTransactionModelConditionInput | null,
};

export type CreateRecentTransactionModelMutation = {
  createRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRecentTransactionModelMutationVariables = {
  input: UpdateRecentTransactionModelInput,
  condition?: ModelRecentTransactionModelConditionInput | null,
};

export type UpdateRecentTransactionModelMutation = {
  updateRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRecentTransactionModelMutationVariables = {
  input: DeleteRecentTransactionModelInput,
  condition?: ModelRecentTransactionModelConditionInput | null,
};

export type DeleteRecentTransactionModelMutation = {
  deleteRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRecentFailedTransactionModelMutationVariables = {
  input: CreateRecentFailedTransactionModelInput,
  condition?: ModelRecentFailedTransactionModelConditionInput | null,
};

export type CreateRecentFailedTransactionModelMutation = {
  createRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRecentFailedTransactionModelMutationVariables = {
  input: UpdateRecentFailedTransactionModelInput,
  condition?: ModelRecentFailedTransactionModelConditionInput | null,
};

export type UpdateRecentFailedTransactionModelMutation = {
  updateRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRecentFailedTransactionModelMutationVariables = {
  input: DeleteRecentFailedTransactionModelInput,
  condition?: ModelRecentFailedTransactionModelConditionInput | null,
};

export type DeleteRecentFailedTransactionModelMutation = {
  deleteRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTransactionItemModelMutationVariables = {
  input: CreateTransactionItemModelInput,
  condition?: ModelTransactionItemModelConditionInput | null,
};

export type CreateTransactionItemModelMutation = {
  createTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTransactionItemModelMutationVariables = {
  input: UpdateTransactionItemModelInput,
  condition?: ModelTransactionItemModelConditionInput | null,
};

export type UpdateTransactionItemModelMutation = {
  updateTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTransactionItemModelMutationVariables = {
  input: DeleteTransactionItemModelInput,
  condition?: ModelTransactionItemModelConditionInput | null,
};

export type DeleteTransactionItemModelMutation = {
  deleteTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductModelMutationVariables = {
  input: CreateProductModelInput,
  condition?: ModelProductModelConditionInput | null,
};

export type CreateProductModelMutation = {
  createProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductModelMutationVariables = {
  input: UpdateProductModelInput,
  condition?: ModelProductModelConditionInput | null,
};

export type UpdateProductModelMutation = {
  updateProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductModelMutationVariables = {
  input: DeleteProductModelInput,
  condition?: ModelProductModelConditionInput | null,
};

export type DeleteProductModelMutation = {
  deleteProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRecentTransactionReceiptModelMutationVariables = {
  input: CreateRecentTransactionReceiptModelInput,
  condition?: ModelRecentTransactionReceiptModelConditionInput | null,
};

export type CreateRecentTransactionReceiptModelMutation = {
  createRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRecentTransactionReceiptModelMutationVariables = {
  input: UpdateRecentTransactionReceiptModelInput,
  condition?: ModelRecentTransactionReceiptModelConditionInput | null,
};

export type UpdateRecentTransactionReceiptModelMutation = {
  updateRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRecentTransactionReceiptModelMutationVariables = {
  input: DeleteRecentTransactionReceiptModelInput,
  condition?: ModelRecentTransactionReceiptModelConditionInput | null,
};

export type DeleteRecentTransactionReceiptModelMutation = {
  deleteRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserModelQueryVariables = {
  id: string,
};

export type GetUserModelQuery = {
  getUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserModelsQueryVariables = {
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserModelsQuery = {
  listUserModels?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBySubQueryVariables = {
  sub: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserBySubQuery = {
  getUserBySub?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByUsernameQuery = {
  getUserByUsername?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByWalletAddressQueryVariables = {
  walletAddress: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByWalletAddressQuery = {
  getUserByWalletAddress?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "ModelUserModelConnection",
    items:  Array< {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVerificationTokenModelQueryVariables = {
  id: string,
};

export type GetVerificationTokenModelQuery = {
  getVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVerificationTokenModelsQueryVariables = {
  filter?: ModelVerificationTokenModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVerificationTokenModelsQuery = {
  listVerificationTokenModels?:  {
    __typename: "ModelVerificationTokenModelConnection",
    items:  Array< {
      __typename: "VerificationTokenModel",
      id: string,
      token?: string | null,
      username?: string | null,
      type?: TokenType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVerificationTokenByTokenQueryVariables = {
  token: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVerificationTokenModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetVerificationTokenByTokenQuery = {
  getVerificationTokenByToken?:  {
    __typename: "ModelVerificationTokenModelConnection",
    items:  Array< {
      __typename: "VerificationTokenModel",
      id: string,
      token?: string | null,
      username?: string | null,
      type?: TokenType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVerificationTokenByUsernameQueryVariables = {
  username: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVerificationTokenModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetVerificationTokenByUsernameQuery = {
  getVerificationTokenByUsername?:  {
    __typename: "ModelVerificationTokenModelConnection",
    items:  Array< {
      __typename: "VerificationTokenModel",
      id: string,
      token?: string | null,
      username?: string | null,
      type?: TokenType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignModelQueryVariables = {
  id: string,
};

export type GetCampaignModelQuery = {
  getCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCampaignModelsQueryVariables = {
  filter?: ModelCampaignModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignModelsQuery = {
  listCampaignModels?:  {
    __typename: "ModelCampaignModelConnection",
    items:  Array< {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignByCidQueryVariables = {
  cid: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignByCidQuery = {
  getCampaignByCid?:  {
    __typename: "ModelCampaignModelConnection",
    items:  Array< {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignByTypeQueryVariables = {
  type: CampaignType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignByTypeQuery = {
  getCampaignByType?:  {
    __typename: "ModelCampaignModelConnection",
    items:  Array< {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignByCelebrityQueryVariables = {
  celebrity: Celebrity,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignByCelebrityQuery = {
  getCampaignByCelebrity?:  {
    __typename: "ModelCampaignModelConnection",
    items:  Array< {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignByTypeAndPhaseQueryVariables = {
  celebrity: Celebrity,
  typeAndPhase?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignByTypeAndPhaseQuery = {
  getCampaignByTypeAndPhase?:  {
    __typename: "ModelCampaignModelConnection",
    items:  Array< {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignUserByCampaignIdAndUserIdQueryVariables = {
  campaignId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignUserModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCampaignUserByCampaignIdAndUserIdQuery = {
  getCampaignUserByCampaignIdAndUserId?:  {
    __typename: "ModelCampaignUserModelConnection",
    items:  Array< {
      __typename: "CampaignUserModel",
      id: string,
      userId?: string | null,
      username?: string | null,
      campaignId?: string | null,
      isNftSent?: boolean | null,
      collectionsSent?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSecretsManagerQueryVariables = {
  id: string,
};

export type GetSecretsManagerQuery = {
  getSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSecretsManagersQueryVariables = {
  filter?: ModelSecretsManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSecretsManagersQuery = {
  listSecretsManagers?:  {
    __typename: "ModelSecretsManagerConnection",
    items:  Array< {
      __typename: "SecretsManager",
      id: string,
      publicKey?: string | null,
      privateKey?: string | null,
      enabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageVisitModelQueryVariables = {
  pk: string,
  sk: string,
};

export type GetPageVisitModelQuery = {
  getPageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPageVisitModelsQueryVariables = {
  pk?: string | null,
  sk?: ModelStringKeyConditionInput | null,
  filter?: ModelPageVisitModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPageVisitModelsQuery = {
  listPageVisitModels?:  {
    __typename: "ModelPageVisitModelConnection",
    items:  Array< {
      __typename: "PageVisitModel",
      pk: string,
      sk: string,
      celebrity?: Celebrity | null,
      pathName?: string | null,
      total?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageVisitByCelebrityQueryVariables = {
  celebrity: Celebrity,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageVisitModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPageVisitByCelebrityQuery = {
  getPageVisitByCelebrity?:  {
    __typename: "ModelPageVisitModelConnection",
    items:  Array< {
      __typename: "PageVisitModel",
      pk: string,
      sk: string,
      celebrity?: Celebrity | null,
      pathName?: string | null,
      total?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSmartContractModelQueryVariables = {
  id: string,
};

export type GetSmartContractModelQuery = {
  getSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSmartContractModelsQueryVariables = {
  filter?: ModelSmartContractModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSmartContractModelsQuery = {
  listSmartContractModels?:  {
    __typename: "ModelSmartContractModelConnection",
    items:  Array< {
      __typename: "SmartContractModel",
      id: string,
      address?: string | null,
      celebrity?: Celebrity | null,
      collection?: string | null,
      name?: string | null,
      symbol?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSmartContractByCelebrityQueryVariables = {
  celebrity: Celebrity,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSmartContractModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSmartContractByCelebrityQuery = {
  getSmartContractByCelebrity?:  {
    __typename: "ModelSmartContractModelConnection",
    items:  Array< {
      __typename: "SmartContractModel",
      id: string,
      address?: string | null,
      celebrity?: Celebrity | null,
      collection?: string | null,
      name?: string | null,
      symbol?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSmartContractByCollectionQueryVariables = {
  collection: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSmartContractModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSmartContractByCollectionQuery = {
  getSmartContractByCollection?:  {
    __typename: "ModelSmartContractModelConnection",
    items:  Array< {
      __typename: "SmartContractModel",
      id: string,
      address?: string | null,
      celebrity?: Celebrity | null,
      collection?: string | null,
      name?: string | null,
      symbol?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionModelQueryVariables = {
  id: string,
};

export type GetCollectionModelQuery = {
  getCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCollectionModelsQueryVariables = {
  filter?: ModelCollectionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionModelsQuery = {
  listCollectionModels?:  {
    __typename: "ModelCollectionModelConnection",
    items:  Array< {
      __typename: "CollectionModel",
      id: string,
      address?: string | null,
      tag?: string | null,
      name?: string | null,
      description?: string | null,
      symbol?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      archived?: boolean | null,
      collectibleType?: CollectibleType | null,
      productId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionByTagQueryVariables = {
  tag: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCollectionByTagQuery = {
  getCollectionByTag?:  {
    __typename: "ModelCollectionModelConnection",
    items:  Array< {
      __typename: "CollectionModel",
      id: string,
      address?: string | null,
      tag?: string | null,
      name?: string | null,
      description?: string | null,
      symbol?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      archived?: boolean | null,
      collectibleType?: CollectibleType | null,
      productId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionByCelebrityUsernameQueryVariables = {
  celebrity_username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCollectionByCelebrityUsernameQuery = {
  getCollectionByCelebrityUsername?:  {
    __typename: "ModelCollectionModelConnection",
    items:  Array< {
      __typename: "CollectionModel",
      id: string,
      address?: string | null,
      tag?: string | null,
      name?: string | null,
      description?: string | null,
      symbol?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      archived?: boolean | null,
      collectibleType?: CollectibleType | null,
      productId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemModelQueryVariables = {
  id: string,
};

export type GetItemModelQuery = {
  getItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListItemModelsQueryVariables = {
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemModelsQuery = {
  listItemModels?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractQueryVariables = {
  smart_contract: string,
  token_id?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractQuery = {
  getItemBySmartContract?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemByCelebrityUsernameQueryVariables = {
  celebrity_username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemByCelebrityUsernameQuery = {
  getItemByCelebrityUsername?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemBySmartContractTokenIdQueryVariables = {
  smartContractTokenId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetItemBySmartContractTokenIdQuery = {
  getItemBySmartContractTokenId?:  {
    __typename: "ModelItemModelConnection",
    items:  Array< {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionModelQueryVariables = {
  id: string,
};

export type GetTransactionModelQuery = {
  getTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTransactionModelsQueryVariables = {
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionModelsQuery = {
  listTransactionModels?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionByProductIdQueryVariables = {
  productId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionByProductIdQuery = {
  getTransactionByProductId?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionsByStatusQueryVariables = {
  status: PaymentStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionsByStatusQuery = {
  getTransactionsByStatus?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionsByCelebrityUsernameQueryVariables = {
  celebrity_username: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionsByCelebrityUsernameQuery = {
  getTransactionsByCelebrityUsername?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionsByEntityQueryVariables = {
  entity: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTransactionsByEntityQuery = {
  getTransactionsByEntity?:  {
    __typename: "ModelTransactionModelConnection",
    items:  Array< {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecentTransactionModelQueryVariables = {
  id: string,
};

export type GetRecentTransactionModelQuery = {
  getRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRecentTransactionModelsQueryVariables = {
  filter?: ModelRecentTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecentTransactionModelsQuery = {
  listRecentTransactionModels?:  {
    __typename: "ModelRecentTransactionModelConnection",
    items:  Array< {
      __typename: "RecentTransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecentFailedTransactionModelQueryVariables = {
  id: string,
};

export type GetRecentFailedTransactionModelQuery = {
  getRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRecentFailedTransactionModelsQueryVariables = {
  filter?: ModelRecentFailedTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecentFailedTransactionModelsQuery = {
  listRecentFailedTransactionModels?:  {
    __typename: "ModelRecentFailedTransactionModelConnection",
    items:  Array< {
      __typename: "RecentFailedTransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecentFailedTransactionByUserIpAddressQueryVariables = {
  userIpAddress: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecentFailedTransactionModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRecentFailedTransactionByUserIpAddressQuery = {
  getRecentFailedTransactionByUserIpAddress?:  {
    __typename: "ModelRecentFailedTransactionModelConnection",
    items:  Array< {
      __typename: "RecentFailedTransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionItemModelQueryVariables = {
  id: string,
};

export type GetTransactionItemModelQuery = {
  getTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTransactionItemModelsQueryVariables = {
  filter?: ModelTransactionItemModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionItemModelsQuery = {
  listTransactionItemModels?:  {
    __typename: "ModelTransactionItemModelConnection",
    items:  Array< {
      __typename: "TransactionItemModel",
      id: string,
      itemId?: string | null,
      transactionId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductModelQueryVariables = {
  id: string,
};

export type GetProductModelQuery = {
  getProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductModelsQueryVariables = {
  filter?: ModelProductModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductModelsQuery = {
  listProductModels?:  {
    __typename: "ModelProductModelConnection",
    items:  Array< {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductByCelebrityUsernameQueryVariables = {
  celebrity_username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProductByCelebrityUsernameQuery = {
  getProductByCelebrityUsername?:  {
    __typename: "ModelProductModelConnection",
    items:  Array< {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductByTagQueryVariables = {
  tag: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProductByTagQuery = {
  getProductByTag?:  {
    __typename: "ModelProductModelConnection",
    items:  Array< {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecentTransactionReceiptModelQueryVariables = {
  id: string,
};

export type GetRecentTransactionReceiptModelQuery = {
  getRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRecentTransactionReceiptModelsQueryVariables = {
  filter?: ModelRecentTransactionReceiptModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecentTransactionReceiptModelsQuery = {
  listRecentTransactionReceiptModels?:  {
    __typename: "ModelRecentTransactionReceiptModelConnection",
    items:  Array< {
      __typename: "RecentTransactionReceiptModel",
      id: string,
      userId?: string | null,
      transactionId?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReceiptByTransactionIdQueryVariables = {
  transactionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecentTransactionReceiptModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetReceiptByTransactionIdQuery = {
  getReceiptByTransactionId?:  {
    __typename: "ModelRecentTransactionReceiptModelConnection",
    items:  Array< {
      __typename: "RecentTransactionReceiptModel",
      id: string,
      userId?: string | null,
      transactionId?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionUserModelFilterInput | null,
  username?: string | null,
};

export type OnCreateUserModelSubscription = {
  onCreateUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionUserModelFilterInput | null,
  username?: string | null,
};

export type OnUpdateUserModelSubscription = {
  onUpdateUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionUserModelFilterInput | null,
  username?: string | null,
};

export type OnDeleteUserModelSubscription = {
  onDeleteUserModel?:  {
    __typename: "UserModel",
    id: string,
    sub?: string | null,
    username?: string | null,
    walletAddress?: string | null,
    publicUsername?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    emailVerified?: boolean | null,
    cognitoStatus?: CognitoUserStatusType | null,
    status?: AccountStatus | null,
    role: ROLES,
    active?: boolean | null,
    campaigns?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    celebrity?: Celebrity | null,
    mnemonic?: string | null,
    privateKey?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVerificationTokenModelSubscriptionVariables = {
  filter?: ModelSubscriptionVerificationTokenModelFilterInput | null,
  username?: string | null,
};

export type OnCreateVerificationTokenModelSubscription = {
  onCreateVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVerificationTokenModelSubscriptionVariables = {
  filter?: ModelSubscriptionVerificationTokenModelFilterInput | null,
  username?: string | null,
};

export type OnUpdateVerificationTokenModelSubscription = {
  onUpdateVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVerificationTokenModelSubscriptionVariables = {
  filter?: ModelSubscriptionVerificationTokenModelFilterInput | null,
  username?: string | null,
};

export type OnDeleteVerificationTokenModelSubscription = {
  onDeleteVerificationTokenModel?:  {
    __typename: "VerificationTokenModel",
    id: string,
    token?: string | null,
    username?: string | null,
    type?: TokenType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCampaignModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignModelFilterInput | null,
};

export type OnCreateCampaignModelSubscription = {
  onCreateCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCampaignModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignModelFilterInput | null,
};

export type OnUpdateCampaignModelSubscription = {
  onUpdateCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCampaignModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignModelFilterInput | null,
};

export type OnDeleteCampaignModelSubscription = {
  onDeleteCampaignModel?:  {
    __typename: "CampaignModel",
    id: string,
    cid?: string | null,
    title?: string | null,
    description?: string | null,
    type?: CampaignType | null,
    celebrity?: Celebrity | null,
    userId?: string | null,
    phase?: Phase | null,
    typeAndPhase?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    users?:  {
      __typename: "ModelCampaignUserModelConnection",
      nextToken?: string | null,
    } | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCampaignUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignUserModelFilterInput | null,
  username?: string | null,
};

export type OnCreateCampaignUserModelSubscription = {
  onCreateCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCampaignUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignUserModelFilterInput | null,
  username?: string | null,
};

export type OnUpdateCampaignUserModelSubscription = {
  onUpdateCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCampaignUserModelSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignUserModelFilterInput | null,
  username?: string | null,
};

export type OnDeleteCampaignUserModelSubscription = {
  onDeleteCampaignUserModel?:  {
    __typename: "CampaignUserModel",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    username?: string | null,
    campaignId?: string | null,
    campaign:  {
      __typename: "CampaignModel",
      id: string,
      cid?: string | null,
      title?: string | null,
      description?: string | null,
      type?: CampaignType | null,
      celebrity?: Celebrity | null,
      userId?: string | null,
      phase?: Phase | null,
      typeAndPhase?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isNftSent?: boolean | null,
    collectionsSent?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSecretsManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretsManagerFilterInput | null,
};

export type OnCreateSecretsManagerSubscription = {
  onCreateSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSecretsManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretsManagerFilterInput | null,
};

export type OnUpdateSecretsManagerSubscription = {
  onUpdateSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSecretsManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretsManagerFilterInput | null,
};

export type OnDeleteSecretsManagerSubscription = {
  onDeleteSecretsManager?:  {
    __typename: "SecretsManager",
    id: string,
    publicKey?: string | null,
    privateKey?: string | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePageVisitModelSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitModelFilterInput | null,
};

export type OnCreatePageVisitModelSubscription = {
  onCreatePageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePageVisitModelSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitModelFilterInput | null,
};

export type OnUpdatePageVisitModelSubscription = {
  onUpdatePageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePageVisitModelSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitModelFilterInput | null,
};

export type OnDeletePageVisitModelSubscription = {
  onDeletePageVisitModel?:  {
    __typename: "PageVisitModel",
    pk: string,
    sk: string,
    celebrity?: Celebrity | null,
    pathName?: string | null,
    total?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSmartContractModelSubscriptionVariables = {
  filter?: ModelSubscriptionSmartContractModelFilterInput | null,
};

export type OnCreateSmartContractModelSubscription = {
  onCreateSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSmartContractModelSubscriptionVariables = {
  filter?: ModelSubscriptionSmartContractModelFilterInput | null,
};

export type OnUpdateSmartContractModelSubscription = {
  onUpdateSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSmartContractModelSubscriptionVariables = {
  filter?: ModelSubscriptionSmartContractModelFilterInput | null,
};

export type OnDeleteSmartContractModelSubscription = {
  onDeleteSmartContractModel?:  {
    __typename: "SmartContractModel",
    id: string,
    address?: string | null,
    celebrity?: Celebrity | null,
    collection?: string | null,
    name?: string | null,
    symbol?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollectionModelSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionModelFilterInput | null,
};

export type OnCreateCollectionModelSubscription = {
  onCreateCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCollectionModelSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionModelFilterInput | null,
};

export type OnUpdateCollectionModelSubscription = {
  onUpdateCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCollectionModelSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionModelFilterInput | null,
};

export type OnDeleteCollectionModelSubscription = {
  onDeleteCollectionModel?:  {
    __typename: "CollectionModel",
    id: string,
    address?: string | null,
    tag?: string | null,
    name?: string | null,
    description?: string | null,
    symbol?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    archived?: boolean | null,
    collectibleType?: CollectibleType | null,
    items?:  {
      __typename: "ModelItemModelConnection",
      nextToken?: string | null,
    } | null,
    productId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionItemModelFilterInput | null,
};

export type OnCreateItemModelSubscription = {
  onCreateItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionItemModelFilterInput | null,
};

export type OnUpdateItemModelSubscription = {
  onUpdateItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionItemModelFilterInput | null,
};

export type OnDeleteItemModelSubscription = {
  onDeleteItemModel?:  {
    __typename: "ItemModel",
    id: string,
    name?: string | null,
    description?: string | null,
    smart_contract?: string | null,
    token_id?: number | null,
    collectibleType?: CollectibleType | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    background_color?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    isPresale?: boolean | null,
    archived?: boolean | null,
    collectionId?: string | null,
    isPurchased?: boolean | null,
    status?: ItemStatus | null,
    transactions?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    isSent?: boolean | null,
    smartContractTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionModelFilterInput | null,
};

export type OnCreateTransactionModelSubscription = {
  onCreateTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionModelFilterInput | null,
};

export type OnUpdateTransactionModelSubscription = {
  onUpdateTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionModelFilterInput | null,
};

export type OnDeleteTransactionModelSubscription = {
  onDeleteTransactionModel?:  {
    __typename: "TransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    xenditOngoing?: string | null,
    xenditSuccess?: string | null,
    xenditError?: string | null,
    items?:  {
      __typename: "ModelTransactionItemModelConnection",
      nextToken?: string | null,
    } | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    discount?: number | null,
    celebrity_username?: string | null,
    entity?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    mobileNumber?: string | null,
    phoneNumber?: string | null,
    country?: string | null,
    streetLine1?: string | null,
    streetLine2?: string | null,
    city?: string | null,
    provinceState?: string | null,
    postalCode?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRecentTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionModelFilterInput | null,
};

export type OnCreateRecentTransactionModelSubscription = {
  onCreateRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRecentTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionModelFilterInput | null,
};

export type OnUpdateRecentTransactionModelSubscription = {
  onUpdateRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRecentTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionModelFilterInput | null,
};

export type OnDeleteRecentTransactionModelSubscription = {
  onDeleteRecentTransactionModel?:  {
    __typename: "RecentTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    product?:  {
      __typename: "ProductModel",
      id: string,
      name?: string | null,
      description?: string | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      archived?: boolean | null,
      tokenIds?: Array< number | null > | null,
      mainCollectionId?: string | null,
      discount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    productId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRecentFailedTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentFailedTransactionModelFilterInput | null,
};

export type OnCreateRecentFailedTransactionModelSubscription = {
  onCreateRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRecentFailedTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentFailedTransactionModelFilterInput | null,
};

export type OnUpdateRecentFailedTransactionModelSubscription = {
  onUpdateRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRecentFailedTransactionModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentFailedTransactionModelFilterInput | null,
};

export type OnDeleteRecentFailedTransactionModelSubscription = {
  onDeleteRecentFailedTransactionModel?:  {
    __typename: "RecentFailedTransactionModel",
    id: string,
    xenditId?: string | null,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    paymentType?: PaymentType | null,
    status?: PaymentStatus | null,
    price?: number | null,
    userIpAddress?: string | null,
    userCountry?: string | null,
    userRegion?: string | null,
    userCity?: string | null,
    userUserAgent?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTransactionItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionItemModelFilterInput | null,
};

export type OnCreateTransactionItemModelSubscription = {
  onCreateTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTransactionItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionItemModelFilterInput | null,
};

export type OnUpdateTransactionItemModelSubscription = {
  onUpdateTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTransactionItemModelSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionItemModelFilterInput | null,
};

export type OnDeleteTransactionItemModelSubscription = {
  onDeleteTransactionItemModel?:  {
    __typename: "TransactionItemModel",
    id: string,
    itemId?: string | null,
    item?:  {
      __typename: "ItemModel",
      id: string,
      name?: string | null,
      description?: string | null,
      smart_contract?: string | null,
      token_id?: number | null,
      collectibleType?: CollectibleType | null,
      image?: string | null,
      celebrity_username?: string | null,
      celebrity_country?: string | null,
      external_url?: string | null,
      youtube_url?: string | null,
      media_url?: string | null,
      background_color?: string | null,
      properties?: string | null,
      price?: number | null,
      tag?: string | null,
      enabled?: boolean | null,
      stock?: number | null,
      isPresale?: boolean | null,
      archived?: boolean | null,
      collectionId?: string | null,
      isPurchased?: boolean | null,
      status?: ItemStatus | null,
      userId?: string | null,
      isSent?: boolean | null,
      smartContractTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId?: string | null,
    transaction:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductModelSubscriptionVariables = {
  filter?: ModelSubscriptionProductModelFilterInput | null,
};

export type OnCreateProductModelSubscription = {
  onCreateProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductModelSubscriptionVariables = {
  filter?: ModelSubscriptionProductModelFilterInput | null,
};

export type OnUpdateProductModelSubscription = {
  onUpdateProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductModelSubscriptionVariables = {
  filter?: ModelSubscriptionProductModelFilterInput | null,
};

export type OnDeleteProductModelSubscription = {
  onDeleteProductModel?:  {
    __typename: "ProductModel",
    id: string,
    name?: string | null,
    description?: string | null,
    image?: string | null,
    celebrity_username?: string | null,
    celebrity_country?: string | null,
    external_url?: string | null,
    youtube_url?: string | null,
    media_url?: string | null,
    properties?: string | null,
    price?: number | null,
    tag?: string | null,
    enabled?: boolean | null,
    stock?: number | null,
    archived?: boolean | null,
    collections?:  {
      __typename: "ModelCollectionModelConnection",
      nextToken?: string | null,
    } | null,
    tokenIds?: Array< number | null > | null,
    mainCollectionId?: string | null,
    discount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRecentTransactionReceiptModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionReceiptModelFilterInput | null,
};

export type OnCreateRecentTransactionReceiptModelSubscription = {
  onCreateRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRecentTransactionReceiptModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionReceiptModelFilterInput | null,
};

export type OnUpdateRecentTransactionReceiptModelSubscription = {
  onUpdateRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRecentTransactionReceiptModelSubscriptionVariables = {
  filter?: ModelSubscriptionRecentTransactionReceiptModelFilterInput | null,
};

export type OnDeleteRecentTransactionReceiptModelSubscription = {
  onDeleteRecentTransactionReceiptModel?:  {
    __typename: "RecentTransactionReceiptModel",
    id: string,
    user?:  {
      __typename: "UserModel",
      id: string,
      sub?: string | null,
      username?: string | null,
      walletAddress?: string | null,
      publicUsername?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      emailVerified?: boolean | null,
      cognitoStatus?: CognitoUserStatusType | null,
      status?: AccountStatus | null,
      role: ROLES,
      active?: boolean | null,
      celebrity?: Celebrity | null,
      mnemonic?: string | null,
      privateKey?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "TransactionModel",
      id: string,
      xenditId?: string | null,
      userId?: string | null,
      productId?: string | null,
      paymentType?: PaymentType | null,
      status?: PaymentStatus | null,
      price?: number | null,
      xenditOngoing?: string | null,
      xenditSuccess?: string | null,
      xenditError?: string | null,
      userIpAddress?: string | null,
      userCountry?: string | null,
      userRegion?: string | null,
      userCity?: string | null,
      userUserAgent?: string | null,
      discount?: number | null,
      celebrity_username?: string | null,
      entity?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      mobileNumber?: string | null,
      phoneNumber?: string | null,
      country?: string | null,
      streetLine1?: string | null,
      streetLine2?: string | null,
      city?: string | null,
      provinceState?: string | null,
      postalCode?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
