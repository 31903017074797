import { CognitoUser } from '@aws-amplify/auth';
import { ROLES } from 'src/API';
import { requestUserByUsername } from 'src/modules/user/userService';
import { AsyncReturnType } from 'type-fest';
import create, { StateSelector } from 'zustand';

export type Me = AsyncReturnType<typeof requestUserByUsername>;

type AuthStore = {
  me: Me;
  setAuth: (authenticated: Me) => void;
  cognitoUser: CognitoUser | null;
  setCognitoUser: (cognitoUser: CognitoUser | null) => void;
  logout: () => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  me: null,
  cognitoUser: null,
  setAuth: (authenticated) => set({ me: authenticated }),
  setCognitoUser: (cognitoUser) => set({ cognitoUser }),
  logout: () => set({ me: null, cognitoUser: null }),
}));

/**
 * Selectors
 */
export const getMe: StateSelector<AuthStore, Me | null> = (state) => state.me;
export const getCognitoUser: StateSelector<AuthStore, AuthStore['cognitoUser']> = (state) => state.cognitoUser;
/**
 * Type guards/helpers
 */
export const isAdmin = (auth: Me): boolean => auth?.role === ROLES.Admin;
export const isCelebrity = (auth: Me): boolean => auth?.role === ROLES.Celebrity;
export const isUser = (auth: Me): boolean => auth?.role === ROLES.User;

export const isLoggedin = (auth: Me | null): boolean => auth !== null;
