import { CognitoUser } from '@aws-amplify/auth';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  useToast,
  Text,
  FormLabel,
  Link,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from 'aws-amplify';
import React, { ClipboardEvent, createRef, FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { checkUser } from 'src/modules/auth/authService';
import * as z from 'zod';
import { useAuthStore } from '../../auth/authStore';
import { SignUpInForms } from '../forms/SignUpInForms';
import { useEmailModalStore } from './emailProviderStore';
import Cookies from 'js-cookie';
import Axios from 'axios';

type InputsCode = {
  code: string;
};

const schemaCode = z.object({
  code: z.string().nonempty({ message: 'This is required fields' }).length(6),
});

/** CONSTANTS */
export const ACTIVATION_CODE_LENGTH = 6;
export const ACTIVATION_CODE_PLACEHOLDER: Array<string> = Array(ACTIVATION_CODE_LENGTH).fill('');

export const EmailProvider: FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuthStore((state) => state.me);
  const { toggleEmailModal, setToggleEmailModal } = useEmailModalStore();
  const setAuth = useAuthStore((state) => state.setAuth);
  const [isEnterCode, setIsEnterCode] = useState(false);
  const [myCognitoUser, setMyCognitoUser] = useState<CognitoUser | null>(null);

  const [code, setCode] = useState(ACTIVATION_CODE_PLACEHOLDER);
  const [inputPlaceholder] = useState(ACTIVATION_CODE_PLACEHOLDER);
  const [refs, setRefs] = useState<Array<MutableRefObject<HTMLInputElement>>>([]);

  const initialRef = useRef<any>(null);
  const finalRef = useRef<any>(null);
  const toast = useToast();

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    formState: { errors: errorsCode, isSubmitting: isSubmittingCode, touchedFields: touchedFieldsCode },
    reset: resetCode,
    setValue: setValueCode,
  } = useForm<InputsCode>({
    defaultValues: {
      code: '',
    },
    resolver: zodResolver(schemaCode),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setToggleEmailModal(!!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!toggleEmailModal) {
      onClose();
    } else {
      onOpen();
    }
  }, [toggleEmailModal]);

  const handleCognitoUser = (cognitoUser?: CognitoUser | null, email?: string) => {
    toast({
      position: 'top-right',
      title: 'Verification code',
      description: `A verification code has been sent to to your email ${email}, please check!`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      containerStyle: { zIndex: 99999 },
    });
    setIsEnterCode(true);
    if (cognitoUser) {
      setMyCognitoUser(cognitoUser);
    }
  };

  const onSubmitCode: SubmitHandler<InputsCode> = async (value) => {
    let isCodeValid = true;

    for (const c of code) {
      if (c === '') {
        isCodeValid = false;
      }
    }

    if (isCodeValid) {
      try {
        const result = await Auth.sendCustomChallengeAnswer(myCognitoUser, code.join(''));
        await checkUser(setAuth);
        let refreshToken = result.signInUserSession.refreshToken.token;
        let accessToken = result.signInUserSession.accessToken.jwtToken;
        let idToken = result.signInUserSession.idToken.jwtToken;
        let expDate = new Date();
        let hostName = window.location.hostname;
        expDate.setHours(expDate.getHours() + 24);
        setCookie('userData', JSON.stringify({ AccessToken: accessToken, IdToken: idToken }), expDate, hostName);
        await Axios.post(`${process.env.NEXT_PUBLIC_API_GATEWAY_URL}/user`, {
          email: result.username,
          username: result.username,
          __typename: 'USERS',
          status: 'ENABLED',
          role: 'User',
        });

        if (result.signInUserSession === null) {
          toast({
            position: 'top-right',
            title: 'Error',
            description: 'Please input the correct verification code',
            status: 'error',
            duration: 5000,
            isClosable: true,
            containerStyle: { zIndex: 99999 },
          });
        }
      } catch (err) {
        console.log('error: ', err);
        toast({
          position: 'top-right',
          title: 'Error',
          description:
            "You've entered incorrect verification code, Please input your email address again and you will received a new verification code",
          status: 'error',
          duration: 10000,
          isClosable: true,
          containerStyle: { zIndex: 99999 },
        });
        setCode(ACTIVATION_CODE_PLACEHOLDER);
        setIsEnterCode(false);
      }
    }
  };

  const setCookie = (cookieName: string, cookieValue: any, cookieExpires: any, domain: any) => {
    document.cookie = cookieName + '=' + cookieValue + ';expires=' + cookieExpires + ';path=/;domain=' + domain + ';';
  };

  const handleOnChange = (value: string, i: number) => {
    const newCode = [...code];
    newCode[i] = value;
    setCode(newCode);

    setValueCode('code', newCode.join(''));
    if (value && i !== ACTIVATION_CODE_LENGTH - 1) refs[i + 1].current.focus();
  };

  const handleOnKeyDown = (key: string, i: number) => {
    if (key !== 'Backspace') return;
    const value = code[i];

    if (!value && i !== 0) refs[i - 1].current.focus();
  };

  const handleOnPaste = (e: ClipboardEvent<HTMLDivElement>) => {
    const value = e.clipboardData.getData('Text');
    if (value.length !== ACTIVATION_CODE_LENGTH) return;
    setCode(value.split(''));
    setValueCode('code', value);
    refs[ACTIVATION_CODE_LENGTH - 1].current.focus();
  };

  useEffect(() => {
    for (let i = 0; i < ACTIVATION_CODE_LENGTH; i++) {
      if (refs && refs[i] && refs[i]?.current) {
        refs[i].current.value = code[i];
      }
    }
  }, [code]);

  useEffect(() => {
    setRefs((prevRefs) => inputPlaceholder.map((_, i) => prevRefs[i] || createRef()));
  }, [inputPlaceholder]);

  return (
    <Box>
      <Box>{children}</Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w="400px" mx="1.25rem">
          {isEnterCode ? (
            <Box>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box textAlign="center" m="1.5rem 0">
                  <Text fontSize={'1.5rem'} fontWeight="semibold" color="blackAlpha.900" mb=".25rem">
                    Verify Account
                  </Text>
                  <Text fontSize={'1rem'} color="blackAlpha.700">
                    You’re almost done. Verify your account with the code we’ve sent in your email.
                  </Text>
                </Box>
                <form onSubmit={handleSubmitCode(onSubmitCode)} autoComplete="false">
                  <FormLabel htmlFor="email">
                    <Text fontWeight="medium">Verify Code</Text>
                  </FormLabel>
                  <SimpleGrid columns={6} gap={0} textAlign="center">
                    {inputPlaceholder.map((_, i) => (
                      <Input
                        p={2}
                        w={12}
                        textAlign="center"
                        onPaste={handleOnPaste}
                        ref={refs[i]}
                        key={i}
                        onChange={(e) => handleOnChange(e.target.value, i)}
                        onKeyDown={(e) => handleOnKeyDown(e.key, i)}
                        value={code[i]}
                        maxLength={1}
                        onFocus={() => {}}
                        onBlur={() => {}}
                      />
                    ))}
                  </SimpleGrid>
                  <Input id="code" type="text" {...registerCode('code')} display="none" />
                  {/* <Box textAlign="center" m="1.5rem 0 1rem">
                    <Link _hover={{ textDecoration: 'none' }}>
                      Resend Code 1:30
                    </Link>
                  </Box> */}
                  <Box>
                    <Button
                      variant="darkBTN"
                      mt="1.5rem"
                      w="100%"
                      isLoading={isSubmittingCode}
                      type="submit"
                      _hover={{ textDecoration: 'none', bgColor: 'black' }}
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
              </ModalBody>
            </Box>
          ) : (
            <Box>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box textAlign="center" m="1.5rem 0">
                  <Text fontSize={'1.5rem'} fontWeight="semibold" color="blackAlpha.900" mb=".25rem">
                    Sign in / Sign up
                  </Text>
                  <Text fontSize={'1rem'} color="blackAlpha.700">
                    Returning or new account? Fill in your email address below.
                  </Text>
                </Box>
                <SignUpInForms onCognitoUser={handleCognitoUser} />
                <Box textAlign="center">
                  <Text fontSize={'.75rem'} color="blackAlpha.900">
                    By submitting your email to Anotoys Collectiverse that means you’re okay with our Terms of Services
                    and Privacy Policy.
                  </Text>
                </Box>
              </ModalBody>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};
