import create from 'zustand';

type EmailModalStoreType = {
  toggleEmailModal: boolean;
  setToggleEmailModal: (navbar: boolean) => void;
};

export const useEmailModalStore = create<EmailModalStoreType>((set) => ({
  toggleEmailModal: false,
  setToggleEmailModal: (navbar) => set({ toggleEmailModal: navbar }),
}));
