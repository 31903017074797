import { extendTheme } from '@chakra-ui/react';
import { Button } from 'src/theme/component-styles/buttonStyles';
import NFTCardTheme from 'src/components/NFTCard/nftCardStyles';

const theme = extendTheme({
  colors: {
    gray: {
      900: '#141414',
      800: '#333333',
      700: '#5C5C5C',
      600: '#858585',
      500: '#A3A3A3',
      400: '#C2C2C2',
      300: '#D6D6D6',
      200: '#EBEBEB',
      100: '#F0F0F0',
      50: '#F5F5F5',
    },
    brand: {
      bodyBackground: '#F6F9FD',
      gradientBackground: 'linear-gradient(90deg, #5771DB 0%, #5771DB 0.01%, #63D9CC 100%)',
      heroBackground: '#dcedf6',
      textColor: '#fff',
      primary: {
        900: '#232D58',
        500: '#5771DB',
        400: '#798DE2',
        300: '#ABB8ED',
        200: '#DDE3F8',
        100: '#F6F8FD',
      },
      secondary: {
        900: '#285752',
        500: '#63D9CC',
        400: '#82E1D6',
        300: '#C1F0EB',
        200: '#D0F4F0',
        100: '#EFFBFA',
      },
      tertiary: {
        900: '#4D3D17',
        500: '#E6B843',
        400: '#FFCC4B',
        300: '#FFEBB7',
        200: '#FFF5DB',
        100: '#FFFAED',
      },
      green: '#02C6D6',
    },
    support: {
      success: {
        700: '#086343',
        500: '#008556',
        100: '#D6F3E2',
      },
      warning: {
        600: '#A64F21',
        500: '#E86825',
        100: '#FFE1BE',
      },
      alert: {
        700: '#9F1B1F',
        500: '#DE1C22',
        100: '#F9D3D4',
      },
    },
  },
  fonts: {
    heading: 'Mont-Trial-Regular, sans-serif',
    body: 'Mont-Trial-Regular, sans-serif',
    mono: 'Mont-Trial-Regular, sans-serif',
  },
  components: {
    Button,
    NFTCardTheme,
  },
});

export default theme;
