import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { anatomy } from '@chakra-ui/theme-tools';

export const THEME_KEY_NAME: string = 'NFTCardTheme';

export const parts = anatomy(THEME_KEY_NAME).parts(
  'root',
  'detailContainer',
  'imageContainer',
  'title',
  'subTitle',
  'description',
  'widgets'
);

const nftCardTheme: ComponentMultiStyleConfig = {
  parts: parts.keys,
  baseStyle: {
    root: {
      border: '1px solid var(--chakra-colors-gray-200)',
      boxSizing: 'content-box',
    },
    imageContainer: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    detailContainer: {
      p: 4,
    },
    title: {
      fontSize: 'xl',
    },
    subTitle: {
      fontSize: 'xs',
      color: 'gray.400',
    },
    description: {
      fontSize: 'xs',
      color: 'gray.800',
      mt: 2,
    },
    widgets: {
      mt: 4,
      w: '100%',
      justifyContent: 'space-between',
      '& div': {
        d: 'flex',
        alignItems: 'center',
        '& svg': {
          mr: 2,
        },
      },
    },
  },
  sizes: {
    sm: {},
    md: {
      root: {
        w: { base: '100%', lg: '280px' },
      },
      imageContainer: {
        w: { base: '100%', lg: '280px' },
        h: { base: '160px', lg: '280px' },
      },
      title: {
        fontSize: { base: 'md', lg: 'lg' },
      },
    },
    lg: {
      root: {
        w: { base: '100%', lg: '420px' },
      },
      imageContainer: {
        w: { base: '100%', lg: '420px' },
        h: '420px',
      },
    },
    xl: {
      root: {
        w: '600px',
      },
      imageContainer: {
        w: '600px',
        h: '600px',
      },
    },
  },
  variants: {
    empty: {
      root: {
        border: '2px dashed var(--chakra-colors-gray-200)',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        d: 'flex',
        minH: { base: '200px', lg: '400px' },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
export default nftCardTheme;
